import React from 'react';
import { GiArrowScope } from "react-icons/gi";
import { LiaBinocularsSolid } from "react-icons/lia";
import { BiBarChartAlt2 } from "react-icons/bi";
import { FaArrowRightLong } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import ImageLoading from '../../../components/ImageLoading';
import Translator from '../../../components/I18n/Translator';


function AboutBusiness() {
    return (
        <div className='about-business-area'>
          <div className="wrapper">
          <div className="content">
                <div className="text-header">
                    <div className="title-info center">
                        <strong><Translator path={"about.about_text_area2.small"} /></strong>
                        <h1 className='title'><Translator path={"about.about_text_area2.title"} /></h1>
                        <div className="bar"></div>
                    </div>
                </div>
                <div className="boxes-area">
                    <div className="box-col">
                        <div className="box image">
                            <ImageLoading source="https://assets.entrepreneur.com/content/3x2/2000/20170731105857-businessteam-meeting-teamwork.jpeg" alt="" />
                        </div>
                        <div className="box text">
                            <div className="details">
                                <div className="icon">
                                    <GiArrowScope />
                                </div>
                                <h5><Translator path={"about.about_text_area2.text1"} /></h5>
                                <p><Translator path={"about.about_text_area2.text2"} /></p>
                            </div>
                        </div>
                    </div>
                    <div className="box-full">
                        <div className="box text">
                            <div className="details">
                                <div className="icon">
                                    <BiBarChartAlt2 />
                                </div>
                                <h5><Translator path={"about.about_text_area2.text3"} /></h5>
                             </div>
                            <ul>
                                <li><div className="cr"></div><Translator path={"about.about_text_area2.text4_1"} /></li>
                                <li><div className="cr"></div><Translator path={"about.about_text_area2.text4_2"} /></li>
                                <li><div className="cr"></div><Translator path={"about.about_text_area2.text4_3"} /></li>
                                <li><div className="cr"></div><Translator path={"about.about_text_area2.text4_4"} /></li>
                                <li><div className="cr"></div><Translator path={"about.about_text_area2.text4_5"} /></li>
                                <Link to="/contacts" ><button className="btn"><Translator path={"about.about_text_area2.link"} /><FaArrowRightLong /></button></Link>
                            </ul>
                        </div>
                    </div>
                    <div className="box-col">
                        <div className="box text">
                            <div className="details">
                                <div className="icon">
                                    <LiaBinocularsSolid />
                                </div>
                                <h5><Translator path={"about.about_text_area2.text5"} /></h5>
                                <p><Translator path={"about.about_text_area2.text6"} /></p>
                            </div>
                        </div>
                        <div className="box image">
                            <ImageLoading source="https://img.freepik.com/fotos-premium/foto-de-homem-vestido-de-terno-e-sapatos-se-preparando-para-correr-na-pista-de-corrida_386167-16028.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
    )
}

export default AboutBusiness
