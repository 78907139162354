import React, { useState } from 'react'
import { LightgalleryItem } from "react-lightgallery";
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import { LightgalleryProvider } from "react-lightgallery";
import ImageLoading from '../../../components/ImageLoading';
import Translator from '../../../components/I18n/Translator';
import FsLightbox1 from "fslightbox-react";
import FsLightbox2 from "fslightbox-react";
import FsLightbox3 from "fslightbox-react";

const Office_1 = [
   {
      src: require("../../../images/officeimages/1.1.jpg"),
      alt: "",
      caption: ""
   },
   {
      src: require("../../../images/officeimages/1.2.webp"),
      alt: "",
      caption: ""
   },
   {
      src: require("../../../images/officeimages/1.3.webp"),
      alt: "",
      caption: ""
   },
   {
      src: "https://store.exportech.com.pt/wp-content/uploads/2024/03/projects-5-big.jpg",
      alt: "",
      caption: ""
   },
   {
      src: "https://store.exportech.com.pt/wp-content/uploads/2024/03/projects-6-big.jpg",
      alt: "",
      caption: ""
   },
   {
      src: "https://store.exportech.com.pt/wp-content/uploads/2024/03/projects-7-big.jpg",
      alt: "",
      caption: ""
   },
   {
      src: "https://store.exportech.com.pt/wp-content/uploads/2024/03/projects-8-big.jpg",
      alt: "",
      caption: ""
   },
];

const Office_2 = [
   {
      src: "https://store.exportech.com.pt/wp-content/uploads/2024/03/IMG_6139-min.jpg",
      alt: "",
      caption: ""
   },
   {
      src: "https://store.exportech.com.pt/wp-content/uploads/2024/03/IMG_6144-min-e1711622013600.jpg",
      alt: "",
      caption: ""
   },
   {
      src: "https://store.exportech.com.pt/wp-content/uploads/2024/03/IMG_6147-min.jpg",
      alt: "",
      caption: ""
   },

   {
      src: "https://store.exportech.com.pt/wp-content/uploads/2024/03/IMG_6174-min.jpg",
      alt: "",
      caption: ""
   },
   {
      src: "https://store.exportech.com.pt/wp-content/uploads/2024/03/IMG_6189-min.jpg",
      alt: "",
      caption: ""
   },
   {
      src: "https://store.exportech.com.pt/wp-content/uploads/2024/03/IMG_6177-min.jpg",
      alt: "",
      caption: ""
   },
   
];

const Office_3 = [
   {
      src:require("../../../images/officeimages/2.1.jpg"),
      alt: "",
      caption: ""
   },
   {
      src:require("../../../images/officeimages/2.2.jpg"),
      alt: "",
      caption: ""
   },
   {
      src:  require("../../../images/officeimages/2.3.jpg"),
      alt: "",
      caption: ""
   },

   {
      src: require("../../../images/officeimages/2.4.webp"),
      alt: "",
      caption: ""
   },
   {
      src:require("../../../images/officeimages/2.5.webp"),
      alt: "",
      caption: ""
   },
   {
      src: require("../../../images/officeimages/2.6.webp"),
      alt: "",
      caption: ""
   },
   {
      src: require("../../../images/officeimages/2.7.webp"),
      alt: "",
      caption: ""
   },
 
   {
      src: require("../../../images/officeimages/2.8.webp"),
      alt: "",
      caption: ""
   },
   {
      src: require("../../../images/officeimages/2.9.webp"),
      alt: "",
      caption: ""
   },

];



function AboutOffices() {
   const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 3,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               initialSlide: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };
   const [toggler, setToggler] = useState(false);
   const [CurrentImageIndex, setCurrentImageIndex] = useState(0);

   const [toggler2, setToggler2] = useState(false);
   const [CurrentImageIndex2, setCurrentImageIndex2] = useState(0);

   const [toggler3, setToggler3] = useState(false);
   const [CurrentImageIndex3, setCurrentImageIndex3] = useState(0);

   function PopImageLightBox(e) {
      setCurrentImageIndex(e);
      setToggler(!toggler);
   }

   function PopImageLightBox2(e) {
      setCurrentImageIndex2(e);
      setToggler2(!toggler2);
   }

   function PopImageLightBox3(e) {
      setCurrentImageIndex3(e);
      setToggler3(!toggler3);
   }

   return (
      <div className='about-offices-area'>
         <div className="wrapper">
            <div className="header-area">
               <div className="title-info center">
                  <strong><Translator path={"about.about_text_area3.small"} /></strong>
                  <h1 className='title'><Translator path={"about.about_text_area3.title"} /></h1>
                  <div className="bar"></div>
               </div>
            </div>
            <aside>
               <div className="space-area">
                  <div className="flex"><div className="count">1</div><h5> <Translator path={"about.about_text_area3.text1"} /> </h5> </div>
                  <div className='lk'><span><Link to="/contacts"><Translator path={"about.about_text_area3.link"} /></Link></span></div>
               </div>
               <FsLightbox1
                  sourceIndex={CurrentImageIndex}
                  toggler={toggler}
                  sources={[
                     require("../../../images/officeimages/1.1.jpg"),
                     require("../../../images/officeimages/1.2.webp"),
                     require("../../../images/officeimages/1.3.webp"),
                     require("../../../images/officeimages/1.4.webp"),
                     require("../../../images/officeimages/1.5.webp"),
                     require("../../../images/officeimages/1.6.webp"),
                     require("../../../images/officeimages/1.7.webp"),
                  ]} /> 
               <FsLightbox2
                  sourceIndex={CurrentImageIndex2}
                  toggler={toggler2}
                  sources={[
                     require("../../../images/officeimages/2.1.jpg"),
                     require("../../../images/officeimages/2.2.jpg"),
                     require("../../../images/officeimages/2.3.jpg"),
                     require("../../../images/officeimages/2.4.webp"),
                     require("../../../images/officeimages/2.5.webp"),
                     require("../../../images/officeimages/2.6.webp"),
                     require("../../../images/officeimages/2.7.webp"),
                     require("../../../images/officeimages/2.8.webp"),
                     require("../../../images/officeimages/2.9.webp"),
                  ]} />
               <FsLightbox3
                  sourceIndex={CurrentImageIndex3}
                  toggler={toggler3}
                  sources={[ 
                     require("../../../images/officeimages/3.1.webp"),
                     require("../../../images/officeimages/3.2.webp"),
                     require("../../../images/officeimages/3.3.webp"),
                     require("../../../images/officeimages/3.4.webp"),
                     require("../../../images/officeimages/3.5.webp"),
                     require("../../../images/officeimages/3.6.webp"),
                  ]} />
               <Slider {...settings}>
                  {Office_1.map((item, index) => (
                     <article> 
                        <ImageLoading  onClick={() => PopImageLightBox(index)} source={item.src} alt={item.alt} /> 
                     </article>
                  ))}
               </Slider>
            </aside>
            <aside>
               <div className="space-area">
                  <div className="flex"><div className="count">2</div><h5> <Translator path={"about.about_text_area3.text2"} /> </h5> </div>
                  <div className='lk'><span><Link to="/contacts"><Translator path={"about.about_text_area3.link"} /></Link> </span></div>
               </div>
               <Slider {...settings}>
                  {Office_2.map((item, index) => (
                     <article key={index}> 
                         <ImageLoading  onClick={() => PopImageLightBox3(index)} source={item.src} alt={item.alt} /> 
                     </article>
                  ))}
               </Slider>
            </aside>
            <aside>
               <div className="space-area">
                  <div className="flex"><div className="count">3</div><h5> <Translator path={"about.about_text_area3.text3"} /> </h5></div>
                  <div className='lk'><span><Link to="/contacts"><Translator path={"about.about_text_area3.link"} /></Link> </span></div>
               </div>
               <Slider {...settings}>
                  {Office_3.map((item, index) => (
                     <article key={index}> 
                           <ImageLoading onClick={() => PopImageLightBox2(index)}  source={item.src} alt={item.alt} /> 
                     </article>
                  ))}
               </Slider>
            </aside>

         </div>

      </div>
   )
}

export default AboutOffices
