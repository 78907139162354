import React from 'react'
import Slider from "react-slick";
import ImageLoading from '../../components/ImageLoading';
import Translator from '../../components/I18n/Translator';

function PartnersSection() {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 9,
    slidesToScroll: 9,
  };
  return (
    <div className='partners-section' >
      <div class="page-section pt-110-b-30-cont"    >
        <div className="wrapper">
          <div className="info-area">
            <div className="text-area">
            <div className="title-info">
               <strong><Translator path={"home.home_text_area6.small"} /></strong>
                  <h1 className='title' ><Translator path={"home.home_text_area6.title"} /></h1>
                      <div className="bar"></div>
                  </div>
                  <p><Translator path={"home.home_text_area6.description"} /></p> 
                  <p><Translator path={"home.home_text_area6.description2"} /></p>  
                  <p><Translator path={"home.home_text_area6.text1"} /></p> 
                  <a href="https://store.exportech.com.pt/registo/" target="_blank" rel="noopener noreferrer"> 
                      <button className='btn'><Translator path={"home.home_text_area6.button"} /></button>
                  </a> 
            </div>
            <div className="image">
            <a href="https://store.exportech.com.pt/registo/" target="_blank" rel="noopener noreferrer">
               <ImageLoading source="images/smartphone.png" />
            </a> 
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartnersSection
