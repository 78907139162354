import React from 'react'
import { LuQuote } from "react-icons/lu";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaPlay } from "react-icons/fa";
import { Link } from 'react-router-dom';
import ImageLoading from '../../../components/ImageLoading';
import Translator from '../../../components/I18n/Translator';

function AboutText() {
    return (
        <div className='about-text-area'>
            <div className="wrapper">
                <div className="content">
                    <div className="block-text">
                        <div className="title-info">
                            <strong><Translator path={"about.about_text_area1.small"} /></strong>
                            <h1 className="title"><Translator path={"about.about_text_area1.title"} /></h1>
                            <div className="bar"></div>
                        </div> 
                        <p><Translator path={"about.about_text_area1.text1"} /></p> 
                        <p><Translator path={"about.about_text_area1.text2"} /></p>
                        <p><Translator path={"about.about_text_area1.text3"} /></p>
                        <p><Translator path={"about.about_text_area1.text4"} /> </p> 

                        <div className="bar"></div>
                        <Link to="/contacts"><button className="btn"><Translator path={"about.about_text_area1.button"} /> <FaArrowRightLong /></button></Link>
                    </div>
                    <div className="block-image">
                        <div className="images-area">
                            <div className="image-box image-box-1">
                                <ImageLoading  height={450}  source={require("../../../images/about.jpg")} alt="" />
                            </div> 
                            <div className="image-box  image-box-2">
                                <ImageLoading height={450} source="https://store.exportech.com.pt/wp-content/uploads/2024/03/IMG_6144-min-e1711622013600.jpg.webp" alt="" /> 
                            </div>
                        </div>
                        <div className="block-info">
                            <h3><Translator path={"about.about_text_area1.text6"} /></h3>
                            <div className="quote"><LuQuote /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutText
