import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Pages/Home';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from './Pages/About';
import Contacts from './Pages/Contacts';
import Services from './Pages/Services';
import Products from './Pages/Products';
import PoliticsAndPrivacy from './Pages/PoliticsAndPrivacy';
 

function App() {

  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contacts' element={<Contacts />} /> 
          <Route path='/products' element={<Products />} />
          <Route path='/privacy_and_policy' element={<PoliticsAndPrivacy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App; 