import React from 'react';
import { LuMapPin } from "react-icons/lu";
import { LuPhoneCall } from "react-icons/lu";
import { RiTimeLine } from "react-icons/ri";
import Translator from '../../../components/I18n/Translator';


function ContactInfo() {
    return (
        <div className='contact-info-area'>
            <div className="wrapper">
                <div className="content">
                    <div className="map-box">
                        <iframe frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=exportech&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
                    </div>
                    <div className="info-box">
                        <div className="title-info">
                            <strong><Translator path={"contacts.contacts_text_area1.small"} /></strong>
                            <h1 className='title'><Translator path={"contacts.contacts_text_area1.title"} /></h1>
                            <div className="bar"></div>
                            <p><Translator path={"contacts.contacts_text_area1.description"} /></p>
                        </div>

                        <div className="box-info">
                            <div className="icon">
                                <LuMapPin />
                            </div>
                            <div>
                                <h5><Translator path={"contacts.contacts_text_area1.text1"} /></h5>
                                <p>Rua Fernando Farinha nº 2A e 2B Braço de Prata - Lisboa 1950-448</p>
                            </div>
                        </div>

                        <div className="box-info">
                            <div className="icon"><LuPhoneCall /></div>
                            <div>
                                <h5><Translator path={"contacts.contacts_text_area1.text2"} /></h5>
                                <p>Email :  +351 210 353 555 | +351 212 456 082</p>
                                <p><Translator path={"contacts.contacts_text_area1.text3"} />:  geral@exportech.com.pt</p>
                            </div>
                        </div>

                        <div className="box-info">
                            <div className="icon"><RiTimeLine /></div>
                            <div>
                                <h5><Translator path={"contacts.contacts_text_area1.text4"} /></h5>
                                <p><Translator path={"contacts.contacts_text_area1.text5"} /></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo
