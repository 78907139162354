import React from 'react'
import { Link } from 'react-router-dom'; 
import { FaPlay } from "react-icons/fa";  
import { CiGlobe } from "react-icons/ci";
import Translator from '../../components/I18n/Translator';
import ImageLoading from '../../components/ImageLoading';


function Header() { 
    return (
        <div className='expo-header'>
            <div className="wall">
                <ImageLoading source="https://store.exportech.com.pt/wp-content/uploads/2020/02/exportech-header.jpeg.webp" />
            </div>
            <div className="header-content">
                <div className="wrapper-page">
                    <div className="wrapper">
                        <div className="header-info">
                            <h1>Exportech  <strong>Portugal</strong></h1>
                            <h4 className='text-white'>YOUR SECURITY PARTNER</h4>
                            <div className="line-bar">
                                <div className="ln"></div>
                                 <div className="icon"><CiGlobe /> </div>
                                <div className="ln"></div>
                            </div>
                            <p><Translator path={"header.description"} /></p>
                            
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default Header
