import React from 'react'
import PageHeader from './Components/PageHeader';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import ContactInfo from './Components/ContactsComponents/ContactInfo';
import { LuPhoneCall } from 'react-icons/lu';
import { HiOutlineMail } from 'react-icons/hi';
import ContactOffices from './Components/ContactsComponents/ContactOffices';
import ImageLoading from '../components/ImageLoading';
import Translator from '../components/I18n/Translator';

function Contacts() {
    document.title = "Contactos";
    const Data = [ 
        {
            image: require("../images/Team/Paulo.png"),
            email: "paulo.ferreira@exportech.com.pt",
            function: "Account Manager",
            phone: "(+351) 21 035 3555",
            name: "Paulo Ferreira",
        }, 
        {
            image: require("../images/Team/Rui.png"),
            email: "rui.guedelha@exportech.com.pt",
            function: "Account Manager",
            phone: "(+351) 968 084 534",
            name: "Rui Guedelha",
        }, 
        {
            image: require("../images/Team/Germano.png"),
            email: "germano.oliveira@exportech.com.pt",
            function: "Account Manager",
            phone: "(+351) 936 480 464",
            name: "Germano Oliveira",
        },  
        {
            image:require("../images/Team/Jose.png"),
            email: "jose.carvalho@exportech.com.pt",
            function: "Account Manager",
            phone: "(+351) 934 444 006",
            name: "José Carvalho",
        },   
    ];

    return (
        <div>
            <Navbar active={5} />
            <PageHeader Title={<Translator path="contacts.title_header" />} title2={"Contatos"} />
            <ContactInfo />
            <div className='about-employees bg-sv'>
                <div className="wrapper">
                    <div className="text-header">
                        <div className="block">
                            <div className="title-info center"> 
                                <h1 className='title'><Translator path={"contacts.contacts_text_area2.title"} /></h1>
                                <div className="bar"></div>
                             </div>
                        </div>
                    </div>
                    <section className="employees-area">
                        {
                            Data.map((item, index) => (
                                <article key={index} >
                                    <ImageLoading height={450} source={item.image} />
                                    <div className="image-over">
                                        <div className="social-el">
                                            <a href={`mailto:${item.email}`}>
                                                <div className="box">
                                                    <span></span>
                                                    <div className="icon"><HiOutlineMail /></div>
                                                </div>
                                            </a>
                                            <a href={`tel:${item.phone}`}>
                                                <div className="box">
                                                    <span></span>
                                                    <div className="icon"><LuPhoneCall /></div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="dets">
                                            <h3>{item.function}</h3>
                                            <h6>{item.name}</h6>
                                        </div>
                                    </div>
                                </article>
                            ))
                        }
                    </section>
                </div>
            </div>
            <ContactOffices />
            <Footer />
        </div>
    )
}

export default Contacts
