import React from 'react'
import { FaInfo } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Translator from '../../components/I18n/Translator';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { FaVectorSquare } from "react-icons/fa";
import { LuFactory } from "react-icons/lu";
import { MdUpdate } from "react-icons/md"; 
import { RxLapTimer } from "react-icons/rx";
import { TbMoneybag } from "react-icons/tb";


function AchievementsSection() {
    return (
        <div>
            <div id="counter-1" class="page-section  achievements-section">
                <div class="wrapper">
                     <div className="ach-content">

                <div className="title-info center">
                   <strong><Translator path={"home.home_text_area5.small"} /></strong>
                       <h1 className='title'><Translator path={"home.home_text_area5.title"} /></h1>
                       <div className="bar"></div>
                       <p><Translator path={"home.home_text_area5.description"} /></p>
                   </div>
                   <br /><br />
                    <div class="row text-center"> 

                        <div class="col-xs-6 col-sm-3">
                          <Link to="/about"><div className="count ct"><TbMoneybag /></div></Link>
                            <div className="inverted-radius"> 
                                <div className="info">
                                    <div class="count-number"><strong><Translator path={"home.home_text_area5.text1"} /></strong></div>
                                    <div class="count-descr">
                                        <span class="count-title"><Translator path={"home.home_text_area5.text2"} /></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-6 col-sm-3">
                        <Link to="/about"><div className="count ct"><FaVectorSquare /></div></Link>
                            <div className="inverted-radius"> 
                                <div className="info">
                                    <div class="count-number"><strong><Translator path={"home.home_text_area5.text3"} /></strong></div>
                                    <div class="count-descr">
                                        <span class="count-title"><Translator path={"home.home_text_area5.text4"} /></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-6 col-sm-3"> 
                        <Link to="/about"><div className="count ct"><LuFactory /></div></Link>
                            <div className="inverted-radius"> 
                                <div className="info">
                                    <div class="count-number"><strong><Translator path={"home.home_text_area5.text5"} /></strong></div>
                                    <div class="count-descr">
                                        <span class="count-title"><Translator path={"home.home_text_area5.text6"} /></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-6 col-sm-3">
                        <Link to="/about"><div className="count ct"><RxLapTimer /></div></Link>
                            <div className="inverted-radius">
                                <div className="info">
                                    <div class="count-number"><strong><Translator path={"home.home_text_area5.text7"} /></strong> </div>
                                    <div class="count-descr">
                                        <span class="count-title"><Translator path={"home.home_text_area5.text8"} /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
 

                    </div>
                     </div>
                </div>
            </div> 
        </div>
    )
}

export default AchievementsSection
