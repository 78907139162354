import React from 'react'
import { CiGlobe } from 'react-icons/ci'
import { FaPlay } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ImageLoading from '../../components/ImageLoading';

function PageHeader({ Title , title2}) {
    document.title = (title2 !==undefined && title2 !== null ) ? title2 : Title;
    
    return (
        <div className='page-header'>
            <div className='expo-header ph'>
                <div className="wall">
                    <ImageLoading source="https://store.exportech.com.pt/wp-content/uploads/2020/02/exportech-header.jpeg.webp" />
                </div>
                <div className="header-content">
                    <div className="wrapper-page">
                        <div className="wrapper">
                            <div className="header-info">
                                <h1>{Title}</h1>
                                <div className="line-bar">
                                    <div className="ln"></div>
                                    <div className="icon"><CiGlobe /> </div>
                                    <div className="ln"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageHeader
