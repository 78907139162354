import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'; 
import { Link } from 'react-router-dom' 
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { GrLanguage } from "react-icons/gr"; 
import Translator from './Translator';

const I18n = () => {
  const { i18n } = useTranslation()

  function handleChangeLanguage(language) {
    const Langs = ["PT", "EN", "ES"]; 
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
  }
  const selectedLanguage = i18n.language
  const storedLang = localStorage.getItem("language");

  const LanguageSt = ()=>{
    if(storedLang === null || storedLang === undefined){
      i18n.changeLanguage("PT");
    }else{
      i18n.changeLanguage(storedLang);
    } 
  }

  useEffect(() => {
    LanguageSt();
     setTimeout(() => {
      LanguageSt();
     }, 100);
   },[]);



  return (
    <div className="flags-container">
      <DropdownButton id="dropdown-basic-button" title={
        <div className="lang-dropdown">
          <li class="parent">
            <Link to="#"><div class="main-menu-title el-flex"><GrLanguage /> { selectedLanguage  } </div></Link>
          </li>
        </div>} >
        <div onClick={() => handleChangeLanguage('PT')}><Dropdown.Item href="#"  style={{color:selectedLanguage === "PT" ? "#0473eb" : "#000"}}> 
            <div className='flex-tag'><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Portugal.svg/640px-Flag_of_Portugal.svg.png" /><Translator path="navbar.lang1" /></div> 
         </Dropdown.Item></div>
        <div onClick={() => handleChangeLanguage('EN')}><Dropdown.Item href="#"  style={{color:selectedLanguage === "EN" ? "#0473eb" : "#000"}}>
        <div className='flex-tag'><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/United-kingdom_flag_icon_round.svg/2048px-United-kingdom_flag_icon_round.svg.png" /><Translator path="navbar.lang2" /></div>  
         </Dropdown.Item></div>
        <div onClick={() => handleChangeLanguage('ES')}><Dropdown.Item href="#"  style={{color:selectedLanguage === "ES" ? "#0473eb" : "#000"}}>
        <div className='flex-tag'><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Bandera_de_Espa%C3%B1a.svg/1200px-Bandera_de_Espa%C3%B1a.svg.png" /><Translator path="navbar.lang3" /></div> 
          </Dropdown.Item></div>
      </DropdownButton>
    </div>
  )
}

export default I18n
