import React, { useEffect } from 'react'
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import Translator from '../../components/I18n/Translator';
import ImageLoading from '../../components/ImageLoading';
import Marquee from "react-fast-marquee";

function Footer() {
    var settings = {
        dots: false,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 8,
        slidesToScroll: 8,
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 5.5,
                    slidesToScroll: 5.5,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }, []);

    return (
        <div className='footer-area'>
            <div className="items-slide">
                <Marquee speed={100}>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/1.png")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/2.jpg")} alt="" /></div>
                    <div className='img-item sck'><ImageLoading source={require("../../images/slides/3.png")} alt="" /></div>
                    <div className='img-item sc'><ImageLoading source={require("../../images/slides/4.jpg")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/5.jpg")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/6.jpg")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/8.jpg")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/9.jpg")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/11.jpg")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/12.jpg")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/13.png")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/14.svg.png")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/15.svg.png")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/16.png")} alt="" /></div>
                    <div className='img-item scq'><ImageLoading source={require("../../images/slides/17.png")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/18.png")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/19.webp")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/20.jpg")} alt="" /></div>
                    <div className='img-item sck'><ImageLoading source={require("../../images/slides/21.svg.png")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/22.jpg")} alt="" /></div>
                    <div className='img-item s'><ImageLoading source={require("../../images/slides/23.webp")} alt="" /></div>
                    <div className='img-item s'><ImageLoading source={require("../../images/slides/24.png")} alt="" /></div>
                    <div className='img-item s'><ImageLoading source={require("../../images/slides/25.png")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/26.png")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/27.png")} alt="" /></div>
                    <div className='img-item sc'><ImageLoading source={require("../../images/slides/28.png")} alt="" /></div>
                    <div className='img-item sc'><ImageLoading source={require("../../images/slides/29.png")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/30.jpg")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/31.png")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/32.jpg")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/33.webp")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/34.jpg")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/35.webp")} alt="" /></div>
                    <div className='img-item'><ImageLoading source={require("../../images/slides/36.png")} alt="" /></div>
                </Marquee>
            </div>
            <footer class="page-section pt-80 pb-50 footer2-azul  bg-black">
                <div className="footer-content">
                    <div class="wrapper">
                        <aside className='title-info center'>
                            <Link to="/" class="clearfix">
                                <ImageLoading source={require("../../images/logo.png")} class="logo-img" alt="Logo" />
                            </Link>
                            <span>YOUR SECURITY PARTNER</span>
                            <div className="bar"></div>
                            <br />
                        </aside>
                        <div class="row">

                            <div class="col-md-3 col-sm-3 widget">
                                <h4><Translator path={"footer.text1"} /></h4>
                                <div class="footer-2-text-cont">
                                    <address>
                                        Rua Fernando Farinha nº 2A e 2B<br />
                                        Braço de Prata 1950-448 Lisboa
                                    </address>
                                </div>
                                <div class="footer-2-text-cont">
                                    +351 210 353 555 | +351 212 456 082
                                </div>
                                <div class="footer-2-text-cont">
                                    <a class="a-text" href="mailto:geral@exportech.com.pt">geral@exportech.com.pt</a>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 widget">
                                <h4><Translator path={"footer.text2"} /></h4>
                                <div class="footer-2-text-cont">
                                    <address>
                                        Rua da Capela do Amparo
                                        Edifício Alpha Living Loja A
                                        9000-267 Funchal
                                    </address>
                                </div>
                                <div class="footer-2-text-cont">
                                    +351 291 601 603 | +351 968 084 534
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 widget">
                                <h4><Translator path={"footer.text3"} /> </h4>
                                <div class="footer-2-text-cont">
                                    <address>
                                        Estrada do Contador nº 25 - Fracção B
                                        Sesmaria do Colaço
                                        2130-223 Benavente
                                    </address>
                                </div>
                                <div class="footer-2-text-cont">
                                    +351 210 353 555
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 widget">
                                <h4><Translator path={"footer.text4"} /></h4>
                                <ul class="links-list a-text-cont2" >
                                    <li><a href="https://store.exportech.com.pt/" target='_blank'><Translator path={"footer.text5"} /></a></li>
                                    <li><a href="https://store.exportech.com.pt/my-account/" target='_blank'><Translator path={"footer.text6"} /></a></li>
                                    <li><Link to="/about"><Translator path={"footer.text7"} /></Link></li>
                                    <li><Link to="/privacy_and_policy"><Translator path={"footer.text8"} /></Link></li>
                                    <li><a href="https://www.livroreclamacoes.pt/Inicio" target='_blank'><Translator path={"footer.text9"} /></a></li>
                                    <li>
                                        <a href={require("../../RMA-formulario-de-devolucao-de-equipamentos.pdf")} download >
                                            <Translator path={"home.boxes_area.box3.title"} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="footer-2-copy-cont clearfix">
                            <div class="footer-2-soc-a right">
                                <a href="https://www.linkedin.com/in/exportech-portugal-30354a178/"
                                    className='social-icon' title="Linkedin" target="new">
                                    <FaLinkedin />
                                </a>
                                <a href="https://www.facebook.com/ExportechPortugal/?fref=ts" className='social-icon' title="Facebook" target="new">
                                    <FaFacebook />
                                </a>
                            </div>
                            <div class="left">
                                ©Exportech Portugal  {new Date().getFullYear()}
                            </div>
                        </div>

                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
