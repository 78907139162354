import React from 'react'
import PageHeader from './Components/PageHeader';
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Translator from '../components/I18n/Translator';


function PoliticsAndPrivacy() {
    document.title = "Políticas de Privacidade";
    const [ActiveTab, setActiveTab] = useState(1);

    return (
        <div className='terms_and_conditions'>
            <Navbar active={0} />
            <PageHeader title2="Política de Privacidade" Title={<Translator path={"Terms.block1.title_header"} />} />
            <section className="wrapper">
                <div className="content">
                    <div className="tabs-area">
                        <div className={ActiveTab === 1 ? "tab-el active" : "tab-el"} onClick={() => setActiveTab(1)}><Translator path={"Terms.block1.title_header"} /></div>
                        <div className={ActiveTab === 2 ? "tab-el active" : "tab-el"} onClick={() => setActiveTab(2)}><Translator path={"Terms.block2.title_header"} /></div>
                        <div className={ActiveTab === 3 ? "tab-el active" : "tab-el"} onClick={() => setActiveTab(3)}><Translator path={"Terms.block3.title_header"} /></div>
                    </div>
                    <br />
                    <aside>
                        <section className={ActiveTab === 1 ? "active" : ""}>
                            <div>
                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text1.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text1.p1"} /></p>
                                <p><Translator path={"Terms.block1.text1.p2"} /></p>
                                <p><Translator path={"Terms.block1.text1.p3"} /></p>
                                <br />

                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text2.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text2.p1"} /></p>
                                <p><Translator path={"Terms.block1.text2.p2"} /></p>
                                <p><Translator path={"Terms.block1.text2.p3"} /></p>
                                <br />

                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text3.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text3.p1"} /></p>
                                <p><Translator path={"Terms.block1.text3.p2"} /></p>
                                <p><Translator path={"Terms.block1.text3.p3"} /></p>
                                <br />

                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text4.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text4.p1"} /></p>
                                <p><Translator path={"Terms.block1.text4.p2"} /></p>
                                <p><Translator path={"Terms.block1.text4.p3"} /></p>
                                <p><Translator path={"Terms.block1.text4.p4"} /></p>
                                <p><Translator path={"Terms.block1.text4.p5"} /></p>
                                <p><Translator path={"Terms.block1.text4.p6"} /></p>
                                <p><Translator path={"Terms.block1.text4.p7"} /></p>
                                <p><Translator path={"Terms.block1.text4.p8"} /></p>
                                <p><Translator path={"Terms.block1.text4.p9"} /></p>
                                <p><Translator path={"Terms.block1.text4.p10"} /></p>
                                <p><Translator path={"Terms.block1.text4.p11"} /></p>
                                <br />

                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text5.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text5.p1"} /></p>
                                <p><Translator path={"Terms.block1.text5.p2"} /></p>
                                <p><Translator path={"Terms.block1.text5.p3"} /></p>
                                <p><Translator path={"Terms.block1.text5.p4"} /></p>
                                <p><Translator path={"Terms.block1.text5.p5"} /></p>
                                <p><Translator path={"Terms.block1.text5.p6"} /></p>
                                <p><Translator path={"Terms.block1.text5.p7"} /></p>
                                <p><Translator path={"Terms.block1.text5.p8"} /></p>
                                <p><Translator path={"Terms.block1.text5.p9"} /></p>
                                <p><Translator path={"Terms.block1.text5.p10"} /></p>
                                <p><Translator path={"Terms.block1.text5.p11"} /></p>
                                <p><Translator path={"Terms.block1.text5.p12"} /></p>
                                <p><Translator path={"Terms.block1.text5.p13"} /></p>
                                <br />

                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text6.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text6.p1"} /></p>
                                <br />

                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text7.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text7.p1"} /></p>
                                <br />

                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text8.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text8.p1"} /></p>
                                <p><Translator path={"Terms.block1.text8.p2"} /></p>
                                <br />

                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text9.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text9.p1"} /></p>
                                <p><Translator path={"Terms.block1.text9.p2"} /></p>
                                <p><Translator path={"Terms.block1.text9.p3"} /></p>
                                <p><Translator path={"Terms.block1.text9.p4"} /></p>
                                <p><Translator path={"Terms.block1.text9.p5"} /></p>
                                <p><Translator path={"Terms.block1.text9.p6"} /></p>
                                <p><Translator path={"Terms.block1.text9.p7"} /></p>
                                <p><Translator path={"Terms.block1.text9.p8"} /></p>
                                <p><Translator path={"Terms.block1.text9.p9"} /></p>
                                <br />

                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text10.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text10.p1"} /></p>
                                <p><Translator path={"Terms.block1.text10.p2"} /></p>
                                <p><Translator path={"Terms.block1.text10.p3"} /></p>
                                <p><Translator path={"Terms.block1.text10.p4"} /></p>
                                <p><Translator path={"Terms.block1.text10.p5"} /></p>
                                <br />

                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text11.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text11.p1"} /></p>
                                <p><Translator path={"Terms.block1.text11.p2"} /></p>
                                <p><Translator path={"Terms.block1.text11.p3"} /></p>
                                <p><Translator path={"Terms.block1.text11.p4"} /></p>
                                <p><Translator path={"Terms.block1.text11.p5"} /></p>
                                <p><Translator path={"Terms.block1.text11.p6"} /></p>
                                <p><Translator path={"Terms.block1.text11.p7"} /></p>
                                <p><Translator path={"Terms.block1.text11.p8"} /></p>
                                <br />

                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text12.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text12.p1"} /></p>
                                <p><Translator path={"Terms.block1.text12.p2"} /></p>
                                <p><Translator path={"Terms.block1.text12.p3"} /></p>
                                <p><Translator path={"Terms.block1.text12.p4"} /></p>
                                <p><Translator path={"Terms.block1.text12.p5"} /></p>
                                <p><Translator path={"Terms.block1.text12.p6"} /></p>
                                <p><Translator path={"Terms.block1.text12.p7"} /></p>
                                <br />


                                <p><strong className='txt-blue'><Translator path={"Terms.block1.text13.title"} /></strong></p>
                                <p><Translator path={"Terms.block1.text13.p1"} /></p>
                                <p><Translator path={"Terms.block1.text13.p2"} /></p>
                                <p><Translator path={"Terms.block1.text13.p3"} /></p>
                                <p><Translator path={"Terms.block1.text13.p4"} /></p>
                                <p><Translator path={"Terms.block1.text13.p5"} /></p>
                                <p><Translator path={"Terms.block1.text13.p6"} /></p>
                                <p><Translator path={"Terms.block1.text13.p7"} /></p>
                                <p><Translator path={"Terms.block1.text13.p8"} /></p>
                                <p><Translator path={"Terms.block1.text13.p9"} /></p>
                                <p><Translator path={"Terms.block1.text13.p7"} /></p>
                                <p><Translator path={"Terms.block1.text13.p8"} /></p>
                                <p><Translator path={"Terms.block1.text13.p9"} /></p>
                                <br /> 

                            </div>
                        </section>
                        <section className={ActiveTab === 2 ? "active" : ""}>

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text1.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text1.p1"} /></p>
                            <p><Translator path={"Terms.block2.text1.p2"} /></p>
                            <p><Translator path={"Terms.block2.text1.p3"} /></p>
                            <br />

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text2.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text2.p1"} /></p> 
                            <br />

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text3.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text3.p1"} /></p>
                            <p><Translator path={"Terms.block2.text3.p2"} /></p> 
                            <br />

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text4.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text4.p1"} /></p> 
                            <br />

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text5.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text5.p1"} /></p> 
                            <br />

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text6.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text6.p1"} /></p>
                            <p><Translator path={"Terms.block2.text6.p2"} /></p>
                            <p><Translator path={"Terms.block2.text6.p3"} /></p>
                            <p><Translator path={"Terms.block2.text6.p4"} /></p>
                            <p><Translator path={"Terms.block2.text6.p5"} /></p>
                            <br />

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text7.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text7.p1"} /></p>
                            <p><Translator path={"Terms.block2.text7.p2"} /></p>
                            <p><Translator path={"Terms.block2.text7.p3"} /></p>
                            <p><Translator path={"Terms.block2.text7.p4"} /></p> 
                            <br />

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text8.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text8.p1"} /></p>
                            <p><Translator path={"Terms.block2.text8.p2"} /></p>
                            <br />

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text9.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text9.p1"} /></p>
                            <p><Translator path={"Terms.block2.text9.p2"} /></p>
                            <p><Translator path={"Terms.block2.text9.p3"} /></p>
                            <p><Translator path={"Terms.block2.text9.p4"} /></p>
                            <p><Translator path={"Terms.block2.text9.p5"} /></p>
                            <p><Translator path={"Terms.block2.text9.p6"} /></p> 
                            <br />

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text10.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text10.p1"} /></p>
                            <p><Translator path={"Terms.block2.text10.p2"} /></p>
                            <p><Translator path={"Terms.block2.text10.p3"} /></p>
                            <p><Translator path={"Terms.block2.text10.p4"} /></p>
                            <p><Translator path={"Terms.block2.text10.p5"} /></p>
                            <p><Translator path={"Terms.block2.text10.p6"} /></p>
                            <p><Translator path={"Terms.block2.text10.p7"} /></p>
                            <br />

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text11.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text11.p1"} /></p> 
                            <br />

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text12.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text12.p1"} /></p> 
                            <br />

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text13.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text13.p1"} /></p>
                            <p><Translator path={"Terms.block2.text13.p2"} /></p>
                            <p><Translator path={"Terms.block2.text13.p3"} /></p>
                            <p><Translator path={"Terms.block2.text13.p4"} /></p>
                            <p><Translator path={"Terms.block2.text13.p5"} /></p> 
                            <br />
 
                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text14.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text14.p1"} /></p> 
                            <br /> 

                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text15.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text15.p1"} /></p> 
                            <br />
 
                            <p><strong className='txt-blue'><Translator path={"Terms.block2.text16.title"} /></strong></p>
                            <p><Translator path={"Terms.block2.text16.p1"} /></p> 
                            <br />

                        </section>
                        <section className={ActiveTab === 3 ? "active" : ""}>
                            <div  >
                                <p><strong><Translator path={"Terms.block3.subtitle"} /></strong></p> 
                                <p>
                                    <strong><Translator path={"Terms.block3.text1.numb"} /></strong>&nbsp;  <Translator path={"Terms.block3.text1.txt"} />
                                </p><br />
                                <p>
                                    <strong><Translator path={"Terms.block3.text2.numb"} /></strong>&nbsp;  <Translator path={"Terms.block3.text2.txt"} />
                                </p><br />
                                <p>
                                    <strong><Translator path={"Terms.block3.text3.numb"} /></strong>&nbsp;  <Translator path={"Terms.block3.text3.txt"} />
                                </p><br />
                                <p>
                                    <strong><Translator path={"Terms.block3.text4.numb"} /></strong>&nbsp;  <Translator path={"Terms.block3.text4.txt"} />
                                </p><br />
                                <p>
                                    <strong><Translator path={"Terms.block3.text5.numb"} /></strong>&nbsp;  <Translator path={"Terms.block3.text5.txt"} />
                                </p><br />
                                <p>
                                    <strong><Translator path={"Terms.block3.text6.numb"} /></strong>&nbsp;  <Translator path={"Terms.block3.text6.txt"} />
                                </p><br />
                                <p>
                                    <strong><Translator path={"Terms.block3.text7.numb"} /></strong>&nbsp;  <Translator path={"Terms.block3.text7.txt"} />
                                </p><br />
                                <p>
                                    <strong><Translator path={"Terms.block3.text8.numb"} /></strong>&nbsp;  <Translator path={"Terms.block3.text8.txt"} />
                                </p><br />
                                <p>
                                    <strong><Translator path={"Terms.block3.text9.numb"} /></strong>&nbsp;  <Translator path={"Terms.block3.text9.txt"} />
                                </p><br />
                                <p>
                                    <strong><Translator path={"Terms.block3.text10.numb"} /></strong>&nbsp;  <Translator path={"Terms.block3.text10.txt"} />
                                </p><br />
                                <p>
                                    <strong><Translator path={"Terms.block3.text11.numb"} /></strong>&nbsp;  <Translator path={"Terms.block3.text11.txt"} />
                                </p><br />
                                <p>
                                    <strong><Translator path={"Terms.block3.text12.numb"} /></strong>&nbsp;  <Translator path={"Terms.block3.text12.txt"} />
                                </p><br /> 
                                    
                            </div>
                        </section>
                    </aside>
                    <br /><br />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default PoliticsAndPrivacy;
