import React from 'react'
import { HiOutlineMail } from "react-icons/hi";
import { LuPhoneCall } from "react-icons/lu";
import ImageLoading from '../../../components/ImageLoading';
import Translator from '../../../components/I18n/Translator';


function AboutEmployees() {
    const Data = [ 
        {
            image:require("../../../images/Team/Paulo.png"),
            email: "paulo.ferreira@exportech.com.pt",
            function: "Account Manager",
            phone: "(+351) 21 035 3555",
            name: "Paulo Ferreira",
        },  
          
        {
            image: require("../../../images/Team/Rui.png"),
            email: "rui.guedelha@exportech.com.pt",
            function: "Account Manager",
            phone: "(+351) 968 084 534",
            name: "Rui Guedelha",
        }, 
        {
            image:require("../../../images/Team/Germano.png"),
            email: "germano.oliveira@exportech.com.pt",
            function: "Account Manager",
            phone: "(+351) 936 480 464",
            name: "Germano Oliveira",
        } ,
        
        {
            image:require("../../../images/Team/Jose.png"),
            email: "jose.carvalho@exportech.com.pt",
            function: "Account Manager",
            phone: "(+351) 934 444 006",
            name: "José Carvalho",
        },  
        {
            image:require("../../../images/Team/Fabio.png"),
            email: "fabio.catela@exportech.com.pt",
            function: "Gestor de Produto",
            phone: "(+351) 21 035 3555",
            name: "Fábio Catela",
        },




        {
            image: require("../../../images/Team/Emerson.png"),
            email: "emerson.oliveira@exportech.com.pt",
            function: "Intrusion & CCTV Technician",
            phone: "(+351) 936 480 471",
            name: "Emerson Oliveira",
        },
        {
            image: require("../../../images/Team/Nuno.png"),
            email: "nuno.valente@exportech.com.pt",
            function: "Fire & Intrusion Technician",
            phone: "(+351) 961 032 022",
            name: "Nuno Valente",
        },  


        {
            image:require("../../../images/Team/Jorge.png"),
            email: "jorge.silva@exportech.com.pt",
            function: "Responsável de Logística",
            phone: "(+351) 21 035 3555",
            name: "Jorge Silva",
        }, 



        {
            image:require("../../../images/Team/miguel.png"),
            email: "miguel.neves@exportech.com.pt",
            function: "Operador Logístico",
            phone: "(+351) 21 035 3555",
            name: "Miguel Neves",
        },  
 
        {
            image:require("../../../images/Team/Ivo.png"),
            email: "ivo.ferreira@exportech.com.pt",
            function: "2Smart Helpdesk",
            phone: "(+351) 210 353 555",
            name: " Ivo Ferreira",
        },   
        {
            image: require("../../../images/Team/Beto.png"),
            email: "beto.silva@exportech.com.pt",
            function: "2Smart Helpdesk",
            phone: "(+351) 927 975 712",
            name: "Beto Silva",
        },  

        {
            image:require("../../../images/Team/Paula.png"),
            email:"paula.neves@exportech.com.pt",
            function:"Software Engineer",
            phone:"(+351) 961 032 022",
            name: "Paula Neves",
        }, 
        {
            image: require("../../../images/Team/Nelson.png"),
            email: "nelson.ornelas@exportech.com.pt",
            function: "Software Architect",
            phone: "(+351) 21 035 3555",
            name: "Nelson Ornelas",
        },   
        {
            image: require("../../../images/Team/Kiosso.png"),
            email: "kiosso.silva@exportech.com.pt",
            function: "FrontEnd developer",
            phone: "(+351) 21 035 3555",
            name: "Kiosso Camuegi",
        },
        {
            image:require("../../../images/Team/pedro.png"),
            email: "pedro.goncalves@exportech.com.pt",
            function: "Web Designer",
            phone: "(+351) 21 035 3555",
            name: "Pedro Gonçalves",
        },  
        {
            image: "https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg",
            email: "tania.martins@exportech.com.pt",
            function: "Financial & Marketing Executive",
            phone: "(+351) 21 035 3555",
            name: "Tânia Martins",
        },  
        {
            image: "https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg",
            email: "geral@exportech.com.pt",
            function: "Financial",
            phone: "(+351) 210 353 555",
            name: "Scarleth Martinez",
        },    
    ];


    return (
        <div className='about-employees'>
            <div className="wrapper">
                <div className="text-header space">
                    <div className="block">
                        <div className="title-info">
                            <strong> <Translator path={"about.about_text_area5.small"} /> </strong>
                            <h1 className='title'> <Translator path={"about.about_text_area5.title"} /> </h1>
                            <div className="bar"></div>
                        </div>
                    </div>
                    <div className="block">
                        <p><Translator path={"about.about_text_area5.description"} /> </p>
                    </div>
                </div>
                <section className="employees-area">
                    {
                        Data.map((item, index) => ( 
                            <article key={index} >
                                <ImageLoading height={450}  source={item.image} />
                                <div className="image-over">
                                    <div className="social-el">
                                        <a href={`mailto:${item.email}`}>
                                        <div className="box">
                                            <span></span>
                                            <div className="icon"><HiOutlineMail /></div>
                                        </div>
                                        </a>
                                        <a href={`tel:${item.phone}`}>
                                        <div className="box">
                                            <span></span>
                                            <div className="icon"><LuPhoneCall /></div>
                                        </div>
                                        </a> 
                                    </div>
                                    <div className="dets"> 
                                        <h3>{item.function}</h3>
                                        <h6>{item.name}</h6>
                                    </div>
                                </div>
                            </article>
                        ))
                    }
                    <div></div>
                    <div></div>
                    <div></div>
                </section>
            </div>
        </div>
    )
}

export default AboutEmployees
