import React from 'react'
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
 import AboutSections from './Components/AboutSections'
import Informationsection from './Components/Informationsection'
import OfficeGallery from './Components/OfficeGallery'
import ClientsSection from './Components/ClientsSection'
import AchievementsSection from './Components/AchievementsSection'
import PartnersSection from './Components/PartnersSection'
import Header from './Components/Header'

function Home() {
    document.title = "Exportech Portugal";
  return (
    <div>
        <Navbar active={1} />
        <Header />
        <AboutSections />
        <Informationsection />
        <AchievementsSection  /> 
        <PartnersSection  />  
        <Footer />
    </div>
  )
}

export default Home
