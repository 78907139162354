
// { }




const PORTUGUESE = {
  translations: {
    navbar: {
      link1: 'Inicio',
      link2: `Produtos`,
      link3: `Serviços`,
      link4: `Contactos`,
      link5: `Loja`,
      lang1: `Português`,
      lang2: `Inglês`,
      lang3: `Espanhol`,
    },
    header: {
      description: `Somos uma empresa extremamente dinâmica, composta por Profissionais dotados de Know How e motivados pelos Projectos que estão inseridos. Somos e pretendemos ser cada vez mais uma referência crescente nas Áreas que actuamos.`,
      button: `Saiba mais`
    },
    home: {
      home_text_area1: {
        title: `O seu Parceiro de Segurança`,
        text1: `O sucesso verificado desde a nossa Fundação em`,
        text2: ` 2006 `,
        text3: `apenas é possível quando estamos dotados de uma equipa de profissionais extremamente motivados , `,
        text4: ` um planeamento estratégico bem delineado `,
        text5: `assim como de Parceiros de Excelência que caminham ao nosso lado,  tornando a Exportech Portugal cada vez mais uma refêrencia no mercado.`,
        text6: `Marcas de Prestígio, Equipamentos Fiáveis, Excelente Suporte Técnico e Comercial, Flexibilidade na Negociação e Apoio Financeiro inexcedível. `,
        text7: "#SomosExportech#1"
      },
      circles_text: {
        text1: `Marcas Inovadoras`,
        text2: `Flexiblidade e Adaptabilidade`,
        text3: `Qualidade de Equipamentos`,
      },
      home_text_area2: {
        small: `Apoiamos incondicionalmente quem nos ajuda a crescer`,
        title: `Uma Equipa de Profissionais pronta para o ajudar!`,
        text1: `A Exportech Portugal está dotada de uma área técnica com as mais recentes tecnologias que nos 
            permite prestar Suporte Técnico Remoto aos nossos Parceiros ajudando-os a solucionar os problemas do dia a dia. `,
        text2: `Quer remotamente ou presencialmente, assessoria em obra ou Formação nos nossos escritórios, estamos prontos para o 
            ajudar a crescer dotando a sua equipa técnica do Know How necessário para serem autónomos nas Marcas que Distribuímos. `,
        text3: `Poderá solicitar Formação à medida sempre que considerar necessário na   `,
        text4: ` Academia Exportech`,
        text5: `permitindo o Agendamento da mesma de forma autónoma.`,
        text6: `Poderá solicitar Formação à medida sempre que considerar necessário na  `,
        text7: `Academia Exportech `,
        text8: `permitindo o Agendamento da mesma de forma autónoma. `,
      },
      boxes_area: {
        box1: {
          title: `Suporte Tecnico`,
          description: `Entre em contacto e obtenha Assistência Técnica Remota por parte dos nossos Técnicos`,
        },
        box2: {
          title: `Suporte Presencial`,
          description: `Agende com um dos nossos Técnicos a Assistência presencial`
        },
        box3: {
          title: `Formulário de RMA`,
          description1: `Efetue download do `,
          description2:" formulário de RMA",
          description3:" , preencha corretamente e envie para geral@exportech.com.pt para um pronto tratamento do mesmo."
        },
        box4: {
          title: `Contactos`,
        },
      },
      home_text_area3: {
        description: `Prometemos-vos competência, competitividade e inovação para vos munir das ferramentas necessárias 
           para crescerem ao nosso lado, um acompanhamento comercial e técnico mais próximo e um Portfólio de Soluções completo e diversificado.`,
        text1: `Desenvolvimento`,
        text2: `Soluções de Segurança integradas e Software de Gestão de Assiduidades Cloud 2Smart HRM`,
        text3: `Crescimento`,
        text4: `Crescemos continuamente desde 2006 resultado de Parcerias sólidas`,
        text5: `Marcas`,
        text6: `Representamos 30 Fabricantes de Prestígio`,
        text7: `Clientes`,
        text8: `Contamos com 1.500 Parceiros que nos ajudam a crescer anualmente`,
      },
      home_text_area4: {
        small: `Porquê a Exportech Portugal`,
        title: `O que nos diferencia`,
        description: `O nosso Portfólio de Soluções de Segurança e Networking é hoje mais completo do que nunca! Diversidade,
        Fiabilidade e Competitividade.`,
        text1: `Inovação`,
        text2: `Linha Estratégica`,
        text3: `Inovamos produtos e processos continuamente para que sejamos hoje melhores que ontem. Inovar é parte do nosso ADN.`,
        text4: `Solidez`,
        text5: `Crescimento Anual `,
        text6: `Crescemos de forma sólida desde 2006 refletindo uma estratégia de sucesso`,
        text7: `Honestidade`,
        text8: `Postura Rigorosa`,
        text9: `Uma Postura Rigorosa, de Transparência para com os nossos Parceiros, boa gestão de expectativas, 
        informação constante e sempre disponível`,
        text10: `Flexibilidade`,
        text11: `Apoio Incondicional`,
        text12: `Flexibilidade na negociação e no apoio financeiro. Uma Estratégia deve ser flexível e adaptável 
        às mudanças constantes do mercado e a cada Parceiro`,
        text13: `Qualidade`,
        text14: `Seleção Rigorosa das Marcas`,
        text15: `Qualidade a um Preço competitivo. Selecionamos de forma muito rigorosa as marcas e Produtos que Distribuímos 
        garantindo elevados padrões de qualidade`,
        text16: `SAIBA MAIS`,
      },
      home_text_area5: {
        small: `Conquistámos muito, mas ambicionamos mais`,
        title: `Conquistas ao longo dos anos`,
        description: `17 Anos de aprendizagem, centenas de projectos desenhados em conjunto com os nossos Parceiros,
      momentos de convívio e amizade com e muitos sorrisos fizeram-nos chegar até aqui `,
        text1: `4.4M€`,
        text2: `Faturação em 2023`,
        text3: `3,000M2`,
        text4: `Área Logística`,
        text5: `30`,
        text6: `Fabricantes Representados`,
        text7: `17`,
        text8: `Anos de Aprendizagem`,
      },
      home_text_area6: {
        small: `Todos os caminhos apontam para o Sucesso`,
        title: `Torne-se nosso Parceiro`,
        description: `Um Parceiro sólido e de confiança, sempre disponível para o apoiar em todas as vertentes.`,
        description2: `Efetue Registo no nosso Site e obtenha acesso a um Portfólio extremamente diversificado e competitivo. `,
        text1: `Vamos crescer juntos ?`,
        button: `Efetue Registo no site`
      },
    },
    footer: { 
      text1: "Sede Lisboa",
      text2: "Filial Funchal",
      text3: "Armazém Logístico",
      text4: "Navegação",
      text5: "Loja Online",
      text6: "Conta de Cliente",
      text7: "Sobre nós",
      text8: "Política de privacidade",
      text9: "Livro de reclamações",
    },
    about: {
      title_header: `A Qualidade de ser Diferente`,
      about_text_area1: {
        small: `Nós Somos`,
        title: `Líder na Distribuição de Tecnologias de Segurança & Networking`,
        text1: `Nascemos em 2006 e somos orgulhosamente uma empresa de Capital 100% Português.`,
        text2: `Os nossos Pilares fundamentais assentam na Inovação constante das nossas soluções, na Honestidade e Integridade nas relações que estabelecemos com os nossos Parceiros, 
        no Suporte Técnico inexcedível e no compromisso e entrega que assumimos junto de quem nos faz crescer. `,
        text3: `O Sucesso da nossa estratégia assenta numa equipa de Profissionais de excelência, com pensamento fora da caixa, ambiciosos 
        e sempre disponíveis para os nossos Parceiros, uma selecção criteriosa de Fabricantes e Soluções Técnológicas inovadoras. `,
        text4: `Dispomos de um Portfólio composto por 6.000 artigos com uma elevada disponibilidade de stock,
       três Armazéns com capacidade logística combinada de 3.000m2 e 30 Fabricantes Representados.`,
        text5: `Estamos repletos de Energia que nos permite ambicionar a continuidade do nosso crescimento.. Estamos prontos para mais!`,
        text6: `Estamos repletos de Energia que nos permite ambicionar a continuidade do nosso crescimento.`,
        button: `Entrar em contacto `
      },
      about_text_area2: {
        small: `O nosso Negócio`,
        title: `Destaca-se do resto`,
        text1: `Nossa Missão`,
        text2: `Identificar e distribuir soluções tecnológicas de segurança electrónica`,
        text3: `Nossos Valores Fundamentais`,
        text4_1: `Inovação`,
        text4_2: `Integridade`,
        text4_3: `Honestidade`,
        text4_4: `Compromisso`,
        text4_5: `Flexibilidade`,
        text5: ` Nossa Visão `,
        text6: `Procura incessante pela melhoria das nossas soluções.. Hoje melhores que ontem. `,
        link: `Entre em contacto`
      },
      about_text_area3: {
        small: `Locais diferentes mas com o mesmo objectivo`,
        title: `Saiba onde estamos localizados`,
        link: `Entrar em contacto`,
        text1: `Exportech - Sede  Lisboa`,
        text2: `Exportech  - Filial Funchal`,
        text3: `Exportech - Armazém Logístico`,
      },
      about_text_area4: {
        small: `Perfil da empresa`,
        title: `Somos diferentes em muitos aspectos`,
        description: `Não se consegue resumir toda a representatividade de uma empresa a simples 
         números e tópicos, mas resumindo estes são os nossos números em 2023.`,
        description2: `A seu lado, prometemos continuar a crescer!`
      },
      about_text_area5: {
        small: `Equipa Dedicada`,
        title: `Uma Equipa composta por Profissionais de Excelência `,
        description: `Somos uma Equipa extremamente motivada e com foco em resultados #SomosExportech`
      },
    },
    products: {
      title_header: `Produtos Fascinantes`,
      products_text_area1: {
        title: `A Sua Nova Plataforma de Gestão de Assiduidades Cloud`,
        text1: `Imaginado com detalhe, Planeado com Rigor, concebido
        para Simplificar - O 2Smart revolucionou o
        mercado com base na sua abordagem inovadora e
        visão fora da caixa. Gerir Assiduidades é habitualmente
        uma Tarefa entediante e onde as plataformas
        existentes são baseadas em processos ultrapassados
        que não evoluíram com o tempo e não se focam quer
        nas necessidades do utilizador quer nas necessidades
        dos Gestores de RH.`,
        text2: `O 2Smart HRM simplifica esta Tarefa com base em
        processos de configuração completos mas simples e
        versáteis e com Layouts inovadores para maior
        facilidade e percepção das tarefas relacionadas.`,
        text3: `Power to you...`,
        text4: `Simplifique a gestão de recursos humanos com 2Smart HRM`,
        button: `Saiba mais`
      },
      products_text_area2: {
        small: `O Líder em Inovação e Competitividade`,
        title: `Dispomos de um Portfólio Inigualável, venha conhecer em detalhe as nossas Soluções `,
        text0: `Produtos CCTV`,
        text1: `Cabos`,
        text2: `Networking`,
        text3: `Fibra Óptica`,
        text4: `Sistemas de Incêndio`,
        text5: `Vídeo Porteiros`,
        text6: `Acessórios`,
        text7: `Sistemas de Intrusão `,
        text8: `EAS / Anti-furto`,
        text9: `Torniquetes`,
        text10: `Detecção de Metais`,
        text11: `Detecção Gases`,
        text12: `Sistemas de Emergência`,
        text13: `Soluções de Parqueamento`,
        link: `Visite a nossa Loja online`,
      },
    },
    contacts: {
      title_header: `Contactos`,
      contacts_text_area1: {
        small: `Os nossos contactos`,
        title: `Como entrar em contacto com a nossa Equipa de especialistas`,
        description: `Envie uma mensagem através do formulário que disponibilizamos. Se a sua consulta for urgente, utilize os contactos infra.`,
        text1: `Exportech Portugal - Sede`,
        text2: `Consultas Gerais`,
        text3: `Telefone`,
        text4: `Horário de funcionamento`,
        text5: `Segunda - Sexta: 09h00 às 18h00 (Sábado & Domingo: Encerrados)`,
      },
      contacts_text_area2: {
        title: `Somos uma Equipa extremamente motivada e com foco em resultados -  Vamos trabalhar juntos? `,
      },
      contacts_text_area3: {
        small: `locais diferentes mas com o mesmo objectivo`,
        title: `Saiba onde estamos localizados`,
        link: `Ver no mapa`
      },
    },

    Terms: {
      block1: {
        title_header: `Política de Privacidade`,
        text1: {
          title: `1. COMPROMISSO`,
          p1: `A proteção da privacidade dos utilizadores e dos seus dados pessoais constitui um compromisso fundamental da Exportech Portugal.`,
          p2: ` A Exportech Portugal aconselha a leitura da presente Política de Privacidade.`,
          p3: `Esta Política de Privacidade foi redigida de forma clara e simples, para facilitar a sua compreensão, e decidir de forma 
            livre e voluntaria se pretende facultar os seus dados pessoais e explica quais são os dados pessoais que a Exportech Portugal 
            recolhe através das interações consigo e como esses dados são utilizados. `,

        },
        text2: {
          title: `2. ÂMBITO DE APLICAÇÃO, RESPONSÁVEL PELO TRATAMENTO DE DADOS E ENCARREGADO DA PROTEÇÃO DE DADOS`,
          p1: `A presente Política de Privacidade aplica-se aos dados pessoais dos nossos utilizadores, clientes, fornecedores, candidatos e demais stakeholders.`,
          p2: `O responsável pela recolha e tratamento dos seus dados pessoais são as empresas da Exportech Portugal que lhe fornecessem produtos e/ou serviços e que nesse âmbito decide quais os dados recolhidos, meios de tratamento e finalidades para que os dados são usados.`,
          p3: `A Exportech Portugal nomeou um Encarregado da Proteção de Dados (Data Protection Officer ou DPO), que monitoriza a conformidade do tratamento de dados com as normas aplicáveis,
            é um ponto de contacto com o utilizador para esclarecimento de questões relativas ao tratamento dos seus dados, coopera com as autoridades de controlo e presta informação e aconselha o responsável pelo tratamento ou o subcontratante sobre as suas obrigações no âmbito da privacidade e proteção de dados.`,
        },
        text3: {
          title: `3. DADOS PESSOAIS, TITULARES DE DADOS PESSOAIS E CATEGORIAS DE DADOS PESSOAIS`,
          p1: `«Dados Pessoais», refere-se à informação relativa a uma pessoa singular identificada ou identificável («Titular dos Dados»); é considerada identificável uma pessoa singular que possa ser identificada, direta ou indiretamente, em especial por referência a um identificador, como por exemplo um nome, um número de identificação, dados de localização, identificadores por via eletrónica ou a um ou mais 
            elementos específicos da identidade física, fisiológica, genética, mental, económica, cultural ou social dessa pessoa singular;`,
          p2: `«Titular dos Dados» é a pessoa singular, a quem os dados dizem respeito;`,
          p3: `Esta Política permite-lhe saber como a Exportech Portugal recolhe e usa os seus Dados Pessoais, como pode controlar o seu uso e descreve as nossas práticas em relação a informação recolhida em todos os sites (incluindo mas não limitando, aplicações de software, redes sociais e mensagens eletrónicas) da Exportech Portugal, bem como, atividades de marketing e de vendas offline (coletivamente, “Canais de Comunicação”). Os Canais de Comunicação podem providenciar links para outras plataformas não pertencentes à Exportech Portugal. Encorajámo-lo a verificar as políticas de privacidade desses websites, serviços, redes sociais ou aplicações antes de fornecer os seus Dados Pessoais, sendo que, não somos responsáveis pelo tratamento dos dados pessoais que eventualmente sejam fornecidos e/ou recolhidos nos mesmos.`,
          p4: `Confiamos que nos fornece informações precisas e completas sobre os seus dados pessoais e que procede à sua atualização em relação a qualquer alteração na informação fornecida.`,

        },
        text4: {
          title: `3.1. QUANDO E QUAL A INFORMAÇÃO RECOLHEMOS SOBRE SI? `,
          p1: `Recolhemos os seus dados pessoais, com o seu consentimento, quando se regista no nosso website ou coloca um pedido de aquisição de produtos ou serviços. Nós também recolhemos os seus dados pessoais quando voluntariamente preenche questionários, formulários ou usa ferramentas de conversação.`,
          p2: `Pedimos-lhe que não envie ou partilhe com a Exportech Portugal qualquer informação pessoal sensível como a origem racial ou étnica, as opiniões políticas, as convicções religiosas ou filosóficas, ou a filiação sindical, bem como dados genéticos, dados biométricos, dados relativos à saúde ou dados relativos à vida ou orientação sexual.`,
          p3: `A informação de uso do website é recolhida usando cookies, mas não o identificam especificamente, sendo ou podendo ser recolhidos os dados seguintes:`,
          p4: `Endereço IP.`,
          p5: `Localização geográfica.`,
          p6: `Informação acerca do dispositivo que está a aceder ao website, nomeadamente, tipo e versão do navegador, sistema operativo, resolução do ecrã, linguagem preferida, informação contida nos cabeçalhos HTTP e versão do agente.`,
          p7: `O motor de busca que usou para localizar e aceder ao website.`,
          p8: `Data e tempo de atividade no website, bem como, páginas web e conteúdos visitados e clicados.`,
          p9: `Importa realçar que esta informação de uso não o identifica deliberadamente. e se excecionalmente (o identificar a mesma será anonimizada de imediato.`,
          p10: `Ao ceder informação está a dar o seu consentimento informado e inequívoco de que consente o tratamento dos dados que lhe digam respeito de forma automatizada., O referido consentimento prévio e expresso do titular dos dados será dado por escrito, oralmente ou através da validação de uma opção.`,
          p11: `Alguns dos dados solicitados são de carater obrigatório e encontram-se devidamente assinalados, podendo a falta ou inexatidão das respostas dadas comprometer o serviço prestado.`,
        },
        text5: {
          title: `3.2. DE QUE FORMA USAMOS A INFORMAÇÃO SOBRE SI?`,
          p1: `Os dados recolhidos servem para processar o seu pedido, gerir a sua conta e, se concordar, enviar-lhe por correio eletrónico informação acerca de outros produtos e serviços.`,
          p2: `Usamos a informação recolhida do website para personalizar as suas próximas visitas ao nosso website.`,
          p3: `Se concordar iremos enviar a sua informação pessoal às diversas empresas da Exportech Portugal para que lhe possam oferecer os seus produtos e serviços. `,
          p4: `A Exportech Portugal não partilha a sua informação para campanhas de marketing com empresas não pertencentes ao grupo nem transfere os seus Dados Pessoais para um país terceiro.`,
          p5: `Embora a informação fornecida seja geralmente tratada como privada na Exportech Portugal, os dados recolhidos serão utilizados em conformidade com o regulamento de proteção de dados pessoais, durante todo o processo 
              de tratamento; pode, no entanto, ser necessário transferir Dados Pessoais de forma privada para outros órgãos, nomeadamente, o judicial, e/ou usar informação de agências/seguradoras de crédito ou semelhantes.`,
          p6: `Tem o direito de apresentar reclamação a uma Autoridade de Controlo Nacional e/ou da União Europeia em relação a qualquer violação dos seus Dados Pessoais. 
              A Exportech Portugal prestará a sua colaboração à Autoridade de Controlo facultando-lhe todas as informações que por esta, no exercício das suas competências, lhe forem solicitadas. `,
          p7: ` A Exportech Portugal só aceita registos de utilizadores com pelo menos 16 anos de idade. Os Canais de Comunicação da Exportech Portugal não são direcionados
               a utilizadores menores de idade. Se souber de algum registo efetuado por um menor agradecemos que nos alerte, a fim de procedermos de imediato à remoção do registo do mesmo. `,
          p8: `Os destinatários ou categorias de destinatários dos seus Dados Pessoais, dependendo da ferramenta de contacto que utilizar são os departamentos que intervêm na relação comercial, 
              os departamentos de Marketing, a Direção de Pessoas, e demais departamentos das diferentes empresas da Exportech Portugal.`,
          p9: `Sempre que transferir ou descarregar um pacote de software em qualquer website de qualquer empresa da Exportech Portugal, poderá ser contacto com os objetivos
              de acompanhar a experiência de utilização e de apresentação do software em causa e/ou de outros produtos semelhantes.`,
          p10: `Decisões Automatizadas: `,
          p11: `A informação recolhida nos websites da Exportech Portugal não envolve a tomada de decisões automatizadas, incluindo a definição de perfis.  `,
          p12: `Os seus dados poderão circular em rede aberta correm o risco de serem vistos e utilizados por terceiros não autorizados.`,
          p13: `Nos websites da Exportech Portugal não são efetuadas comparações, interconexões ou qualquer outra forma de inter-relacionar as informações registadas. `,
        },
        text6: {
          title: `3.3. MARKETING`,
          p1: `Gostaríamos de lhe poder enviar informação acerca dos produtos e serviços das empresas da Exportech Portugal que possam ser ou vir a ser do seu interesse. Se deu 
               o seu consentimento anteriormente pode sempre optar por cancelar a subscrição. Tem o direito em qualquer altura de não 
               permitir que seja contactado para campanhas de marketing. `,
        },
        text7: {
          title: `3.4. CONDIÇÕES GERAIS DE FORNECIMENTO`,
          p1: `As Condições gerais de fornecimento da Exportech Portugal encontram-se disponíveis nos websites da Exportech Portugal.`,
        },
        text8: {
          title: `3.5. TRANSMISSÃO DE DADOS PESSOAIS`,
          p1: `Os seus dados podem ser transmitidos empresas subcontratadas para que estas os tratem em nome e por conta das empresas da Exportech Portugal.`,
          p2: `As entidades subcontratadas, bem como as entidades fornecedoras ou licenciadores de serviços às empresas da Exportech Portugal, poderão ter acesso a dados recolhidos e registados pelas empresas da Exportech Portugal, quando e na medida em que tal se mostre necessário 
               para a oferta ao Cliente de produtos ou serviços comercializados pelas empresas da Exportech Portugal, ou para o cumprimento das obrigações contratuais estabelecidas entre as empresas da Exportech Portugal e o Cliente.`,
        },
        text9: {
          title: `3.6. RECRUTAMENTO E SELEÇÃO`,
          p1: `Ao submeter uma candidatura espontânea ou candidatura a uma oportunidade, autoriza que os seus dados pessoais recolhidos sejam processados e armazenados informaticamente pela Exportech Portugal com a 
              finalidade de poder vir a ser contactado para processos de recrutamento e seleção em aberto, ou que possam surgir no futuro, para as empresas da Exportech Portugal.`,
          p2: `Os seus dados pessoais são conservados durante 2 anos após os quais são eliminados. Deverá submeter nova candidatura após este período caso queira continuar a constar na Base de Dados da Exportech Portugal.`,
          p3: `Caso seja integrado num processo de recrutamento e seleção, esse processo será mantido durante 5 anos.`,
          p4: `Os destinatários ou categorias de destinatários dos seus dados pessoais são a Direção de Pessoas, a Administração, e outras Direções ou funções relevantes para a análise da candidatura.`,
          p5: `Os seus dados pessoais também podem ser partilhados com outras entidades quando exigido por lei ou para responder a um processo legal, e ainda em situações que impliquem a proteção de clientes, a proteção de vidas, a segurança dos serviços, a proteção dos direitos de propriedade ou as empresas da Exportech Portugal.`,
          p6: `Os processos de recrutamento não envolvem a tomada de decisões automatizadas, incluindo a definição de perfis.`,
          p7: `Não são realizadas comparações, interconexões ou qualquer outra forma de inter-relacionar as informações registadas.`,
          p8: `Carece de consentimento o tratamento posterior dos dados pessoais para um fim que não seja aquele para o qual os dados tenham sido recolhidos.`,
          p9: `A Exportech Portugal só aceita candidaturas de pessoas com pelo menos 16 anos, que tenham concluído a escolaridade obrigatória e que disponham de capacidades físicas e psíquicas adequadas ao posto de trabalho.`
        },
        text10: {
          title: `3.7. ÉTICA `,
          p1: `O código de ética da Exportech Portugal estabelece os valores e princípios éticos de atuação da Exportech Portugal e
               como eles se devem refletir nas nossas relações com as diferentes partes interessadas.`,
          p2: `Para denunciar as más práticas éticas, deve consentir com o tratamento dos seus dados pessoais, para esse efeito e como forma de salvaguardar os 
              riscos de denúncias caluniosas e de discriminação. A ausência de consentimento determina o não tratamento da comunicação e a sua eliminação e dos dados nela contidos.`,
          p3: `Os seus dados pessoais serão eliminados imediatamente se se revelarem imprecisos ou inúteis; serão armazenados durante 6 meses após o fim das investigações se não houver processo disciplinar ou judicial e até ao fim do 
              processo se houver processo disciplinar ou judicial. Nesse caso, seus dados pessoais ficarão armazenados num sistema de informação de acesso restrito por um período não superior ao processo disciplinar ou judicial.`,
          p4: `Os seus dados pessoais também podem ser partilhados com outras entidades quando exigido por lei ou para responder a processos judiciais, e também em situações que envolvam a proteção de clientes, proteção de vidas, segurança de serviços, proteção de direitos de propriedade ou empresas da Exportech Portugal .`,
          p5: `Os processos de recrutamento não envolvem tomada de decisão automatizada, incluindo a definição de perfis. Não são feitas comparações, interconexões ou qualquer outra forma de inter-relacionamento das informações registadas.`,
        },
        text11: {
          title: `4. PRAZOS E FUNDAMENTOS DE TRATAMENTO E CONSERVAÇÃO DE DADOS PESSOAIS`,
          p1: `Sem prejuízo de disposições legais ou regulamentares em contrário, os Dados Pessoais serão conservados/tratados apenas pelo
               período mínimo necessário para as finalidades que motivaram a sua recolha ou o seu posterior tratamento.`,
          p2: `Carece de consentimento o tratamento posterior dos Dados Pessoais para um fim que não seja aquele para o qual os dados tenham sido recolhidos, antes desse tratamento as empresas da
               Exportech Portugal, solicitam o respetivo consentimento e fornecem ao Cliente informações sobre esse fim e quaisquer outras informações pertinentes.`,
          p3: `A Exportech Portugal irá manter os seus dados pessoais pelo período de tempo no qual mantiver se mantiver a relação contratual estabelecida com o titular dos dados pessoais.`,
          p4: `Há casos em que a lei obriga ao tratamento e conservação dos dados por um período de tempo mínimo.`,
          p5: `Na falta de obrigação legal específica, então os dados serão tratados/conservados apenas pelo período de tempo necessário para o cumprimento das finalidades que motivaram a sua recolha e preservação ou, 
               consoante o que for aplicável, até que exerça o seu direito de oposição, direito a ser esquecido ou retire o consentimento e sempre de acordo com a lei, as orientações e decisões das autoridades de controlo.`,
          p6: `É assegurado ao Cliente, nos termos legais, o direito de retirar o seu consentimento em qualquer altura, sem comprometer a licitude do tratamento efetuado com base no consentimento previamente dado e o direito de solicitar às empresas da Exportech Portugal acesso aos Dados Pessoais que lhe digam respeito, bem como a sua retificação 
               ou o seu apagamento, e a limitação do tratamento no que disser respeito ao Cliente, ou do direito de se opor ao tratamento, bem como do direito à portabilidade dos dados mediante comunicação escrita dirigida a Exportech Portugal.`,
          p7: `Os Dados Pessoais necessários para a execução do contrato no qual o Cliente é parte, para o cumprimento das obrigações jurídicas a que o Responsável pelo Tratamento esteja sujeito e os que sejam necessários para efeito dos interesses legítimos prosseguidos pelas empresas da Exportech Portugal não estão incluídos no ponto anterior.`,
          p8: `Decorrido o período de conservação/tratamento, a Exportech Portugal eliminará ou, caso seja do interesse das empresas da Exportech Portugal, anonimizará (de tal modo que o Cliente não seja ou já não possa ser identificado) os dados sempre que os mesmos não devam ser conservados para finalidade distinta que possa subsistir.`,

        },
        text12: {
          title: `4.1. FUNDAMENTOS PARA O TRATAMENTO DE DADOS PESSOAIS`,
          p1: `Consentimento: quando tiver o consentimento prévio e expresso do titular dos dados – por escrito, oralmente ou através da validação de uma opção – e se esse consentimento for livre, informado, específico e inequívoco.`,
          p2: `– caso especial dos menores:`,
          p3: `No caso de tratamento de dados pessoais de menores, que possam estar sujeitos a consentimento prévio, a o mesmo deverá ser prestado por parte dos titulares das responsabilidades parentais.`,
          p4: `Execução de contrato: quando o tratamento de dados pessoais seja necessário para a celebração, execução e gestão do contrato celebrado com qualquer das empresas da Exportech Portugal.`,
          p6: `Cumprimento de obrigação legal: quando o tratamento de dados pessoais seja necessário para cumprir uma obrigação legal a que a Exportech Portugal esteja sujeito.`,
          p7: `Interesse legítimo: quando o tratamento de dados pessoais corresponda a um interesse legítimo da Exportech Portugal ou de terceiros.`,
        },
        text13: {
          title: `5. COOKIES`,
          p1: `A Exportech Portugal utiliza cookies em todos os nossos sites para melhorar o desempenho e a sua experiência como utilizador.`,
          p2: `A utilização de cookies pela Exportech Portugal tem os objetivos de analisar o uso do website e permitir-lhe ter uma experiência de navegação sem problemas; em momento algum recolhemos Dados Pessoais através de cookies.`,
          p3: `Os cookies não serão usados para nenhum outro propósito dos mencionados no ponto anterior.`,
          p4: `Cookies são pequenos ficheiros de texto que um site, ao ser visitado pelo utilizador, coloca no seu computador ou no seu dispositivo móvel através do navegador de internet (browser). Esta informação é usada para registar o número de visitas efetuadas e compilar informação estatística acerca da atividade do website.`,
          p5: `Usamos o termo cookies nesta Política para referir todos os ficheiros que recolhem informações desta forma.`,
          p6: `Tipos de Cookies consoante a entidade que os gere:`,
          p7: `Cookies próprios: um cookie gerido por este domínio / website.`,
          p8: `Cookies de terceiros: um cookie gerido por outro domínio / website.`,
          p9: `Cookies persistentes: a informação do cookie encontra-se armazenada de forma permanente no seu equipamento.`,
          p10: `Cookies de sessão: a informação do cookie não se encontra armazenada de forma permanente no seu equipamento.`,
          p11: `A Exportech Portugal usa cookies de sessão (“session cookies”) e cookies próprios (“first-party cookies”) bem como cookies persistentes (“persistent cookies”) e cookies de terceiros (“third-party cookies”).`,
          p12: `Tipos de cookies consoante a finalidade de utilização:`,
          p13: `Cookies técnicos: são aqueles que permitem navegar através de um domínio / website, utilizando as diferentes opções ou serviços como, por exemplo, identificar a sessão, aceder a áreas de acesso restrito e
               realizar o processo de um pedido de compra.`,
          p14: `Cookies de personalização: são aqueles que permitem aceder ao domínio / website com algumas características predefinidos em função de um conjunto de decisões tomadas, como por exemplo a língua utilizada.`,
          p15: `Cookies de análise: são aqueles que permitem a monitorização e análise do comportamento dos utilizadores de websites aos quais estão vinculados. 
               A informação recolhida através de tais cookies é usada, por exemplo, para medir a atividade dos websites e elaborar perfis de navegação.`,
          p16: `Cookies publicitários: são aqueles que permitem a gestão da forma mais eficiente de espaços publicitários com base em critérios como o conteúdo editado ou a frequência em que os anúncios são exibidos.`,
          p17: `Cookies de publicidade comportamental: são aqueles que permitem a gestão da forma mais eficaz possível de espaços publicitários. 
               Estes cookies armazenam informações sobre o comportamento do utilizador, obtidas através da observação contínua dos seus hábitos de navegação, permitindo desenvolver um perfil específico para exibir anúncios com base neste.`
        },
      },
      block2: {
        title_header: `Termos e Condições`,
        text1: {
          title: `1. ÂMBITO DE APLICAÇÃO`,
          p1: `1.1. As presentes Condições Gerais estabelecem as condições aplicáveis às vendas efectuadas pela Exportech, doravante designada por Empresa, e o Cliente, doravante designado por Cliente e regulam os direitos e obrigações das partes, prevalecendo sobre quaisquer disposições legais não imperativas.`,
          p2: `1.2. Qualquer excepção ou alteração às presentes Condições Gerais, assume a forma de Condições Particulares e só será válida se formulada por escrito, aceite e assinada pelos representantes legais das partes, prevalecendo estas sobre as primeiras.`,
          p3: `1.3. Serão nulas e de nenhum valor quaisquer condições ou especificações que o Cliente venha a inserir em documentação de qualquer natureza que sejam contraditórias com o disposto nas Condições Gerais ou Particulares.`, 
        },
        text2: {
          title: `2. SELECÇÃO DO PRODUTO`,
          p1: `2.1. Cliente é o único responsável pela selecção do produto objecto da compra e venda, bem como pelo uso ou função a que se destina. Por conseguinte (e de acordo com o estipulado nos catálogos, tabelas de preços e/ou informações gerais sobre o produto da Empresa), a Empresa não se responsabiliza, nem garante que o produto seja adequado às aplicações técnicas pretendidas pelo Cliente, nem para o alcance, total ou parcial dos objectivos por ele visados ao efectuar a sua compra. As imagens constantes no presente catálogo são meramente ilustrativas podendo divergir do aspecto original do
          equipamento, pelo que, cores formas e acabamentos estão sujeitas a eventuais alterações, sem aviso prévio.`,
        },
        text3: {
          title: `3. ENCOMENDAS `,
          p1: `3.1. Na data da encomenda dos bens e/ou serviços que o Cliente pretende adquirir, este é obrigado a efectuar o pedido por escrito utilizando para isso o formulário da Empresa adequado a esse fim, ou através de email para comecial@store.exportech.com.pt.`,
          p2: `3.2. O prazo de entrega dos bens encomendados, indicado pela Empresa, é meramente indicativo e em caso algum vinculativo, não sendo por isso exigível à Empresa qualquer responsabilidade pela entrega do bem fora do prazo inicialmente indicado.`, 
        },
        text4: {
          title: `4. INSPECÇÃO DE BENS`,
          p1: `4.1. Todos os Bens são vendidos com a qualidade que possuam e no estado em que se encontrem à data de venda. Os Clientes poderão inspeccionar os bens objecto da venda, mediante prévio acordo da Empresa, considerando-se que o Cliente examinou os bens objecto da venda ou renunciou a esse direito a partir do momento em que se apresenta respectiva proposta de compra à Empresa. `, 
        },
        text5: {
          title: `5. QUANTIDADES`,
          p1: `5.1 As eventuais diferenças de quantidades nas vendas de bens realizadas por unidade, deverão imediatamente ser comunicadas à Empresa pelo Cliente, no acto da entrega ou levantamento dos bens. Em qualquer caso a Empresa reserva-se o direito de inspeccionar os referidos bens antes de aceitar a reclamação efectuada pelo Cliente. `,
        },
        text6: {
          title: `6. CONDIÇÕES DE PAGAMENTO`,
          p1: `6.1. Salvo acordo escrito em contrário, e sem prejuízo do estipulado na cláusula 3a, o preço dos bens adquiridos pelo Cliente será por este pago à Empresa a pronto, após a emissão da respectiva factura ou documento equivalente. A falta de pagamento importa a resolução imediata do contrato e constituição em mora do Cliente. `,
          p2: `6.2. O preço dos bens pode ser alterado pela Empresa sem aviso prévio. No entanto será sempre pago o valor que os bens tenham na data da sua encomenda, ou, não tendo a venda sido precedida de encomenda, na data do seu pagamento.`,
          p3: `6.3. Os preços estabelecidos pela Empresa são sujeitos a IVA calculado à taxa legal em vigor à data de emissão da factura de pagamento dos bens adquiridos pelo Cliente.`,
          p4: `6.4. Os bens, mesmo que na posse do Cliente, continuam a ser propriedade da Empresa até que sejam integralmente cumpridas todas as obrigações do Cliente e respectiva liquidação dos bens na integra.`,
          p5: `6.5.Todas as vendas não liquidadas à data do seu vencimento, estão sujeitas a juros de mora à taxa de juro média aplicada pelos bancos portugueses aos descobertos em contas correntes calculados à data do pagamento devido.`,
        },
        text7: {
          title: `7. DISPONIBILIZAÇÃO, ENTREGA E LEVANTAMENTO DE BENS`,
          p1: `7.1. Os bens considerar-se-ão postos à disposição do Cliente no local que venha a ser definido pela Empresa, salvo acordo escrito em contrário.`,
          p2: ` 7.2. Caso os bens sejam entregues em local definido pelo Cliente, as despesas de envio serão da responsabilidade do Cliente, e o risco corre por conta deste último.`,
          p3: `7.3. O Cliente deverá levantar os bens adquiridos no prazo de 3 (três) dias úteis após o pagamento.`,
          p4: `7.4. Caso o Cliente não faça qualquer pagamento aquando da data devida ou não realize atempadamente quaisquer outras obrigações que lhe sejam imputadas, a Empresa poderá suspender pela sua parte o cumprimento dos seus deveres, designadamente, pela limitação ou cessação do apoio técnico.`,
        },
        text8: {
          title: `8. APOIO TÉCNICO `,
          p1: `8.1. O Cliente pode a qualquer momento solicitar os serviços do Departamento Técnico da Empresa, os quais serão prestados e cobrados nas condições e termos a definir pela Empresa. `,
          p2: `8.2. Qualquer custo inerente ao Serviço do Apoio Técnico será previamente comunicado ao Cliente e por este aceite por escrito.`,
        },
        text9: {
          title: `9. RECLAMAÇÕES`,
          p1: `9.1. O prazo de reclamação é de 8 (oito) dias e terá de ser sempre justificada pelo Cliente e acompanhada dos bens que motivaram a reclamação no mesmo estado em que foram vendidos. `,
          p2: `9.2. O cliente deverá solicitar à Empresa a atribuição de um número de reparação / devolução (RMA) que identificará o produto, e sem o qual não será possível a sua análise pelo Departamento Técnico da Empresa.`,
          p3: `9.3. As reclamações só serão aceites após parecer favorável do Departamento Técnico da Empresa a que determinará se o equipamento da reclamação terá de ser reparado ou substituído, conforme decisão da Empresa.`,
          p4: `9.4. O parecer do Departamento Técnico será realizado no prazo máximo de 15 (quinze) dias úteis, após a recepção do material na sede da Empresa.`,
          p5: `9.5. As trocas ou devoluções só serão aceites se o equipamento apresentar defeito comprovado.`,
          p6: `9.6. Todos os equipamentos enviados para orçamentação de reparação ou reparação apenas serão aceites quando acompanhadas pelo formulário RMA (disponível no website da Empresa) devidamente preenchido.`,
        },
        text10: {
          title: `10. REPARAÇÕES `,
          p1: `10.1. A pedido e solicitação do Cliente, e após contacto deste com o Departamento Técnico da Empresa, os equipamentos adquiridos e integralmente pagos pelo Cliente podem ser reparados na sede da Empresa.`,
          p2: `10.2. O Cliente deverá solicitar à Empresa a atribuição de um número de reparação / devolução (RMA) que identificará o produto, e sem o qual não será possível a sua análise pelo Departamento Técnico da Empresa.  `,
          p3: `10.3. Os equipamentos:`,
          p4: `10.3.1. Em que após análise exaustiva não foi detectada qualquer anomalia.`,
          p5: `10.3.2. Sem descrição pormenorizada da avaria.`,
          p6: `10.3.3. Em que o orçamento de reparação proposto não foi aceite estão sujeitas ao pagamento de uma taxa de análise do equipamento que corresponde 15% do valor do mesmo equipamento em novo. `,
          p7: `10.3.4. A Empresa exclui toda e qualquer responsabilidade proveniente do transporte dos equipamentos a reparar.`,
        },
        text11: {
          title: `11. ELEMENTOS DE FORÇA MAIOR RELATIVOS À ENTREGA`,
          p1: `11.1. Após a celebração do Contrato, a Empresa não será responsável por quaisquer atrasos na disponibilização ao Cliente dos bens objectos do Contrato, caso tal atraso seja devido a motivo de força maior, caso fortuito ou, em geral, a situações que não pudessem razoalmente ser previsíveis ou evitáveis.`, 
        },
        text12: {
          title: `12. LIMITES DA RESPONSABILIDADE DA EMPRESA`,
          p1: `12.1. No caso de, por qualquer motivo, se verificar um atraso ou falta de entrega dos bens, um defeito de quantidade, género ou qualquer inexactidão na descrição dos bens objecto do Contrato, ainda que em virtude de culpa ou negligência da Empresa, a responsabilidade deste não cobrirá, em caso algum, os danos indirectos sofridos pelo Cliente, como a perda de uma operação de revenda, negócio ou outros lucros análogos ou lucros cessantes.`, 
        }, 
        text13: {
          title: `13. RESOLUÇÃO`,
          p1: `13.1. A Empresa tem o direito de resolver, total ou parcialmente, o Contrato com efeitos imediatos nos seguintes casos: `,
          p2: `13.1.1. Incumprimento das Condições Gerais e/ou Particulares;`,
          p3: `13.1.2. A sujeição a algum processo de insolvência, a liquidação judicial ou extra judicial do Cliente, a diminuição das garantias prestadas ou, de um modo geral, qualquer modificação da sua estrutura jurídica que afecte a sua solvência;`,
          p4: `13.1.3. Cessação, seja a que título for, ainda que só cessação de facto, de actividade do Cliente;`,
          p5: `13.1.4. Ocorrência de quaisquer outras causas de resolução previstas no Contrato ou na Lei.`, 
        },
        text14: {
          title: `14. REDUÇÃO DO CONTRATO`,
          p1: `14.1. A invalidade, total ou parcial, de qualquer disposição do Contrato, não afectará a validade das restantes, salvo se a parte interessada demonstrar que o fim prosseguido pela Empresa e pelo Cliente permite supor que estas não teriam concluído o negócio sem a parte viciada.`,
        },
        text15: {
          title: `15. CLÁUSULA PENAL`,
          p1: `Em caso de mora no pagamento de uma factura e verificando-se a sua continuação após a interpelação do Cliente para pagamento por parte da Empresa, o Cliente deverá pagar à Empresa, para além do montante relativo à divida principal e respectivo juros, um montante correspondente a 15% do valor em divida, a título de cláusula penal.`,
        },
        text16: {
          title: `16. JURISDIÇÃO COMPETENTE`,
          p1: `Para dirimir qualquer questão emergente do presente contrato, as partes convencionam o Foro da Comarca de Lisboa.`, 
        },
      },
      block3: {
        title_header: `Política de Trocas e Devoluções`,
        subtitle:"CONDIÇÕES DE ACEITAÇÃO",
        text1: {
          numb:"1.",
          txt:`Os RMA´s deverão ser sempre acompanhados do presente Formulário;`
        },   
        text2: {
          numb:"2.",
          txt:`Deverão ser indicados expressamente todos os Acessórios que acompanham o RMA sob pena de não serem aceites posteriormente solicitações de acessórios não indicados. Caso estejam indicados acessórios que não se verifiquem acompanhar o equipamento, o RMA será devolvido sendo os custos de transporte suportados pelo Cliente;`
        },   
        text3: {
          numb:"3.",
          txt:`RMA´s que não revelem qualquer avaria serão expedidos sendo os custos de transporte suportados pelo Cliente e será debitada uma Taxa de 15,00 € pela Assistência prestada;`
        },   
        text4: {
          numb:"4.",
          txt:`Quando em Período de Garantia, o Cliente suporta os custos de Transporte para a Exportech sendo que a Exportech suporta os custos de reenvio para o cliente;`
        },   
        text5: {
          numb:"5.",
          txt:`As solicitações de Crédito deverão ser efectuadas até um Período máximo de 14 Dias Úteis após a emissão da Factura, sendo que a partir dessa Data está sujeita à Avaliação e Aceitação da Exportech Portugal;`
        },   
        text6: {
          numb:"6.",
          txt:`Apenas serão aceites para Crédito Produtos cuja verificação pelos nossos Serviços Técnicos verifique que são validadas as 4 Premissas supra (Funcionamento Normal | Pacote Completo | Caixa em Bom Estado | Equipamento Vendável);`
        },   
        text7: {
          numb:"7.",
          txt:`Equipamentos que não sejam levantados ou cujo Pagamento da respectiva Reparação não seja liquidado em 60 Dias reverterão para a Exportech Portugal;`
        },   
        text8: {
          numb:"8.",
          txt:`Todos os equipamentos fora do período de garantia estão sujeitos a um valor de 15,00 € pela respectiva análise. Este valor será diluído no custo da Reparãção caso o Orçamento seja aceite;`
        },   
        text9: {
          numb:"9.",
          txt:`Todas as Reparações beneficiam de uma Garantia de 3 Meses;`
        },   
        text10: {
          numb:"10.",
          txt:`Todos os equipamentos para nota de crédito têm que mencionar a password do equipamento no campo acima. Em caso de esquecimento ou perda da(s) password(s) a Exportech irá cobrar pelo serviço unitário o valor de 25€+iva.`
        },   
        text11: {
          numb:"11.",
          txt:`Emissão de Notas de Crédito aquando da devolução de equipamentos por parte dos cliente: Até 15 dias* – Não existe desvalorização 16 – 31 dias* – 15% desvalorização 32 – 60 dias* – 40% desvalorização *(assumindo que o equipamento está perfeitamente vendável, embalagem origem, isento de riscos e/ou uso, acessórios, etc); Após 61 dias – A Exportech não aceita a devolução.`
        },   
        text12: {
          numb:"12.",
          txt:`12. Produtos de Projecto ou Encomenda propositada para projecto (Produtos de Não Stock) – A Exportech não aceita a devolução;`
        },   
      }, 
    },

    about_locations: {
       l1:`Sede Exportech Lisboa`,
       l2:`Filial Exportech Funchal`,
       l3:`Armazém Logístico`,
    },

  
  }
}

const ENGLISH = {
  translations: {
    navbar: {
      link1: 'Home',
      link2: `Products`,
      link3: `Services`,
      link4: `Contacts`,
      link5: `Store`,
      lang1: `Portuguese`,
      lang2: `English`,
      lang3: `Spanish`,
    },
    header: {
      description: `We are an extremely dynamic company, made up of professionals with know-how and motivated by the projects we are involved in. We are and communicate that we are increasingly a growing reference in the areas we operate.`,
      button: `Learn more`
    },
    header: {
      description: `We are an extremely dynamic company, made up of professionals with know-how and motivated by the projects we are involved in. We are and intend to increasingly be a growing reference in the areas we operate.`,
      button: `Learn More`
    },
    home: {
      home_text_area1: {
        title: `Your Security Partner`,
        text1: `The success seen since our foundation in`,
        text2: `2006`,
        text3: `it is only possible when we have a team of extremely motivated professionals,`,
        text4: `a well-designed strategic plan`,
        text5: `as well as Partners of Excellence who walk alongside us, making Exportech Portugal increasingly a reference in the market.`,
        text6: `Prestige Brands, Reliable Equipment, Excellent Technical and Commercial Support, Flexibility in Negotiation and Unsurpassed Financial Support. `,
        text7: "#WeAreExportech#1"
      },
      circles_text: {
        text1: `Innovative Brands`,
        text2: `Flexibility and Adaptability`,
        text3: `Equipment Quality`,
      },
      home_text_area2: {
        small: `We unconditionally support those who help us grow`,
        title: `A Team of Professionals ready to help you!`,
        text1: `Exportech Portugal has a technical area with the most recent technologies that 
         allow us to provide Remote Technical Support to our Partners, helping them solve everyday problems. `,
        text2: `Whether remotely or in person, on-site consultancy or training in our offices, we are ready for the 
         help grow by providing your technical team with the Know How necessary to be autonomous in the Brands we Distribute. `,
        text3: `You can request tailor-made training whenever you consider necessary at `,
        text4: `Exportech Academy`,
        text5: `allowing it to be scheduled autonomously.`,
        text6: `You can request tailor-made training whenever you consider necessary at `,
        text7: `Exportech Academy`,
        text8: `allowing it to be scheduled autonomously. `,
      },
      boxes_area: {
        box1: {
          title: `Technical Support`,
          description: `Get in touch and get Remote Technical Assistance from our Technicians`,
        },
        box2: {
          title: `In-person Support`,
          description: `Schedule in-person assistance with one of our technicians`
        },
        box3: {
          title: `RMA Form`,
          description1: `Download `,
          description2:"RMA form",
          description3:" , fill it out correctly and send it to geral@exportech.com.pt for prompt processing."
        },
        box4: {
          title: `Contacts`,
        },
      },
      home_text_area3: {
        description: `We promise you competence, competitiveness and innovation to provide you with the necessary tools 
       to grow alongside us, closer commercial and technical support and a complete and diversified Solutions Portfolio.`,
        text1: `Development`,
        text2: `Integrated Security Solutions and Attendance Management Software Cloud 2Smart HRM`,
        text3: `Growth`,
        text4: `We have grown continuously since 2006 as a result of solid partnerships`,
        text5: `Marks`,
        text6: `We represent 30 Prestigious Manufacturers`,
        text7: `Customers`,
        text8: `We have 1,500 Partners who help us grow annually`,
      },
      home_text_area4: {
        small: `Why Exportech Portugal`,
        title: `What sets us apart`,
        description: `Our Portfolio of Security and Networking Solutions is now more complete than ever! Diversity,
    Reliability and Competitiveness.`,
        text1: `Innovation`,
        text2: `Strategic Line`,
        text3: `We continually innovate products and processes so that we are better today than yesterday. Innovation is part of our DNA.`,
        text4: `Solidity`,
        text5: `Annual Growth`,
        text6: `We have grown solidly since 2006, reflecting a successful strategy`,
        text7: `Honesty`,
        text8: `Strict Posture`,
        text9: `A Rigorous Attitude, of Transparency towards our Partners, good management of expectations, 
    constant and always available information`,
        text10: `Flexibility`,
        text11: `Unconditional Support`,
        text12: `Flexibility in negotiation and financial support. A Strategy must be flexible and adaptable 
    to constant changes in the market and each Partner,`,
        text13: `Quality`,
        text14: `Strict Brand Selection`,
        text15: `Quality at a competitive price. We very rigorously select the brands and products we distribute 
    ensuring high quality standards `,
        text16: `LEARN MORE`,
      },
      home_text_area5: {
        small: `We have achieved a lot, but we aim for more`,
        title: `Achievements over the years`,
        description: `17 Years of learning, hundreds of projects designed together with our Partners,
  moments of conviviality and friendship and lots of smiles made us get here`,
        text1: `4.4M€`,
        text2: `Billing in 2023`,
        text3: `3,000M2`,
        text4: `Logistics Area`,
        text5: `30`,
        text6: `Represented Manufacturers`,
        text7: `17`,
        text8: `Years of Learning`,
      },
      home_text_area6: {
        small: `All paths point to Success`,
        title: `Become our Partner`,
        description: `A solid and trustworthy Partner, always available to support you in all aspects.`,
        description2: `Register on our Website and gain access to an extremely diverse and competitive Portfolio. `,
        text1: `Let's grow together?`,
        button: `Register on the website`
      },
    },
    footer: {
      text1: "Headquarters Lisbon",
      text2: "Funchal Branch",
      text3: "Logistics Warehouse",
      text4: "Navigation",
      text5: "Online Store",
      text6: "Customer Account",
      text7: "About us",
      text8: "Privacy policies",
      text9: "Complaints book",
    },

    about: {
      title_header: `The Quality of being Different`,
      about_text_area1: {
        small: `We Are`,
        title: `Leader in the Distribution of Security & Networking Technologies`,
        text1: `We were born in 2006 and we are proudly a company with 100% Portuguese capital.`,
        text2: `Our fundamental Pillars are based on the constant Innovation of our solutions, on Honesty and Integrity in the relationships we establish with our Partners, 
    in the unsurpassed Technical Support and in the commitment and dedication we assume with those who make us grow. `,
        text3: `The success of our strategy is based on a team of excellent professionals, with Out of the Box thinking, ambitious 
    and always available to our Partners, a careful selection of Manufacturers and innovative Technological Solutions. `,
        text4: `We have a Portfolio made up of 6,000 items with high stock availability,
   three Warehouses with a combined logistics capacity of 3,000m2 and 30 Represented Manufacturers.`,
        text5: `We are full of Energy that allows us to aspire to continue our growth.. We are ready for more!`,
        text6: `We are full of Energy that allows us to aspire to continue our growth.`,
        button: `Get in touch`
      },
      about_text_area2: {
        small: `Our Business`,
        title: `Stands out from the rest`,
        text1: `Our Mission`,
        text2: `Identify and distribute electronic security technological solutions`,
        text3: `Our Core Values`,
        text4_1: `Innovation`,
        text4_2: `Integrity`,
        text4_3: `Honesty`,
        text4_4: `Commitment`,
        text4_5: `Flexibility`,
        text5: `Our Vision`,
        text6: `Incessant search for improving our solutions. Today better than yesterday. `,
        link: `Get in touch`
      },
      about_text_area3: {
        small: `Different places but with the same objective`,
        title: `Learn where we are located`,
        link: `Get in touch`,
        text1: `Exportech - Headquarters Lisbon`,
        text2: `Exportech - Funchal Branch`,
        text3: `Exportech - Logistics Warehouse`,
      },
      about_text_area4: {
        small: `Company Profile`,
        title: `We are different in many ways`,
        description: `It is not possible to summarize all the representation of a company in a simple 
   numbers and topics, but in short these are our numbers in 2023.`,
        description2: `By your side, we promise to continue to grow!`
      },
      about_text_area5: {
        small: `Dedicated Team`,
        title: `A Team made up of Professionals of Excellence`,
        description: `We are an extremely motivated team focused on results #WeAreExportech`
      },
    },

    products: {
      title_header: `Fascinating Products`,
      products_text_area1: {
        title: `Your New Cloud Attendance Management Platform`,
        text1: `Imagined with detail, Planned with Rigor, designed
    to Simplify - 2Smart has revolutionized
    market based on its innovative and
    out of the box view. Managing Attendance is usually
    a tedious task and where the platforms
    existing ones are based on outdated processes
    that have not evolved over time and do not focus either
    the needs of the user or the needs
    of HR Managers.`,
        text2: `2Smart HRM simplifies this Task based on
    complete but simple configuration processes and
    versatile and with innovative Layouts for greater
    ease and perception of related tasks.`,
        text3: `Power to you...`,
        text4: `Simplify HR Management with 2Smart HRM`,
        button: `Learn more`
      },
      products_text_area2: {
        small: `The Leader in Innovation and Competitiveness`,
        title: `We have an Unparalleled Portfolio, come and discover our Solutions in detail`,
        text0: `CCTV Products`,
        text1: `Cables`,
        text2: `Networking`,
        text3: `Optical Fiber`,
        text4: `Fire Systems`,
        text5: `Video Doormen`,
        text6: `Accessories`,
        text7: `Intrusion Systems`,
        text8: `EAS/Anti-theft`,
        text9: `Turniquets`,
        text10: `Metal Detection`,
        text11: `Gases Detection`,
        text12: `Emergency Systems`,
        text13: `Parking Solutions`,
        link: `Visit our online store`,
      },
    },
    contacts: {
      title_header: `Contacts`,
      contacts_text_area1: {
        small: `Our contacts`,
        title: `How to contact our team of experts`,
        description: `Send a message using the form we provide. If your query is urgent, use the contact details below.`,
        text1: `Exportech Portugal - Headquarters`,
        text2: `General Consultations`,
        text3: `Phone`,
        text4: `Opening hours`,
        text5: `Monday - Friday: 9am to 6pm (Saturday & Sunday: Closed)`,
      },
      contacts_text_area2: {
        title: `We are an extremely motivated and results-focused team - Shall we work together?`,
      },
      contacts_text_area3: {
        small: `different places but with the same objective`,
        title: `Learn where we are located`,
        link: `See on map`
      },
    },
 

    Terms: {
      block1: {
        title_header: `Privacy Policy`,
        text1: {
          title: `1. COMMITMENT`,
          p1: `Protecting the privacy of users and their personal data constitutes a fundamental commitment of Exportech Portugal.`,
          p2: `Exportech Portugal advises you to read this Privacy Policy.`,
          p3: `This Privacy Policy was written in a clear and simple way, to facilitate your understanding, and to decide in an informed way. 
            free and voluntary if you wish to provide your personal data and explain what personal data Exportech Portugal 
            Collect through interactions with you and how this data is used. `, 
        },
        text2: {
          title: `2. SCOPE OF APPLICATION, RESPONSIBLE FOR DATA PROCESSING AND RESPONSIBLE FOR DATA PROTECTION`,
          p1: `This Privacy Policy applies to the personal data of our users, customers, suppliers, candidates and other stakeholders.`,
          p2: `The person responsible for collecting and processing your personal data are the Exportech Portugal companies that provide you with products and/or services and that within this scope decides which data is collected, the means of processing and the purposes for which the data is used.` ,
          p3: `Exportech Portugal has appointed a Data Protection Officer (DPO), who monitors the compliance of data processing with applicable standards,
            is a point of contact with the user to clarify issues relating to the processing of their data, cooperates with control authorities and provides information and advises the controller or subcontractor on their obligations regarding privacy and data protection. `,
        },
        text3: {
          title: `3. PERSONAL DATA, PERSONAL DATA SUBJECTS AND PERSONAL DATA CATEGORIES`,
          p1: `«Personal Data», refers to information relating to an identified or identifiable natural person («Data Subject»); 
          an identifiable natural person is considered to be a natural person who can be identified, directly or indirectly, in particular by reference to an identifier, 
          such as a name, an identification number, location data, electronic identifiers or one or more 
            specific elements of the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person;'`,
          p2: `«Data Holder» is the natural person to whom the data relate;`,
          p3: `This Policy allows you to know how Exportech Portugal collects and uses your Personal Data, how you can control its use and describes the
          our practices in relation to information collected on all websites (including but not limited to software applications, social networks and electronic messages) 
          of Exportech Portugal, as well as offline marketing and sales activities (collectively, “Communication Channels”). 
          Communication Channels may provide links to other platforms not belonging to Exportech Portugal.
           We encourage you to check the privacy policies of these websites, services, social networks or applications before providing your Personal Data,
            Therefore, we are not responsible for the processing of personal data that may be provided and/or collected therein.`,
          p4: `We trust that you provide us with accurate and complete information about your personal data and that you update it in 
          regarding any change in the information provided.`,
        },
        text4: {
          title: `3.1. WHEN AND WHAT INFORMATION DO WE COLLECT ABOUT YOU? `,
          p1: `We collect your personal data, with your consent, when you register on our website or place an order to purchase products or services. We also collect your personal 
          data when you voluntarily fill out questionnaires, forms or use chat tools.`,
          p2: `We ask that you do not send or share with Exportech Portugal any sensitive personal information such as racial or ethnic origin, political opinions,
           religious or philosophical convictions, or trade union membership, as well as genetic data, biometric data, relating to health or data relating to life or sexual orientation.`,
          p3: `Website usage information is collected using cookies, but they do not specifically identify you, and the following data is or may be collected:`,
          p4: `IP Address.`,
          p5: `Geographic location.`,
          p6: `Information about the device that is accessing the website, namely, browser type and version, operating system, screen resolution, preferred language, information contained in HTTP headers and agent version.`,
          p7: `The search engine you used to find and access the website.`,
          p8: `Date and time of activity on the website, as well as web pages and content visited and clicked on.`,
          p9: `It is important to highlight that this usage information does not deliberately identify you. and if exceptionally (the identification will be immediately anonymized.`,
          p10: `By providing information you are giving your informed and unequivocal consent that you consent to the processing of data relating to you in an automated manner. The aforementioned prior and express consent of the data subject will be given in writing, orally or through validation of an option.`,
          p11: `Some of the data requested is mandatory and is duly marked, and the lack or inaccuracy of the answers given may compromise the service provided.`,
        },
        text5: {
          title: `3.2. HOW DO WE USE INFORMATION ABOUT YOU?`,
          p1: `The data collected is used to process your order, manage your account and, if you agree, send you information about other products and services by email.`,
          p2: `We use the information collected from the website to personalize your next visits to our website.`,
          p3: `If you agree, we will send your personal information to the various Exportech Portugal companies so that they can offer you their products and services. `,
          p4: `Exportech Portugal does not share your information for marketing campaigns with companies outside the group nor does it transfer your Personal Data to a third country.`,
          p5: `Although the information provided is generally treated as private at Exportech Portugal, the data collected will be used in accordance with personal data protection regulations, throughout the process 
              of treatment; It may, however, be necessary to transfer Personal Data privately to other bodies, namely the judiciary, and/or use information from credit agencies/insurance companies or similar.`,
              p6: `You have the right to lodge a complaint with a National and/or European Union Supervisory Authority in relation to any breach of your Personal Data. 
              Exportech Portugal will provide its collaboration to the Control Authority by providing it with all the information requested by it, in the exercise of its powers. `,
          p7: ` Exportech Portugal only accepts registrations from users who are at least 16 years old. Exportech Portugal's Communication Channels are not directed
               to underage users. If you are aware of any registration made by a minor, we would appreciate it if you alerted us so that we can immediately remove the registration. `,
          p8: `The recipients or categories of recipients of your Personal Data, depending on the contact tool you use, are the departments involved in the commercial relationship, 
              the Marketing departments, the People Department, and other departments of the different Exportech Portugal companies.`,
          p9: `Whenever you transfer or download a software package on any website of any Exportech Portugal company, you may be in contact with the objectives
              to monitor the experience of using and presenting the software in question and/or other similar products.`,

     p10: `Automated Decisions: `,
          p11: `The information collected on Exportech Portugal websites does not involve automated decision-making, including the definition of profiles.  `,
          p12: `Your data may circulate on an open network and run the risk of being seen and used by unauthorized third parties.`,
          p13: `No comparisons, interconnections or any other way of interrelating the recorded information are made on the Exportech Portugal websites. `,
        },
        text6: {
          title: `3.3. MARKETING`,
          p1: `We would like to be able to send you information about the products and services of Exportech Portugal companies that may be or may be of interest to you. If it happened 
               your consent previously you can always choose to unsubscribe. You have the right at any time not to 
               allow you to be contacted for marketing campaigns. `,
        },
        text7: {
          title: `3.4. GENERAL SUPPLY CONDITIONS`,
          p1: `Exportech Portugal's general supply conditions are available on the Exportech Portugal websites.`,
        },
        text8: {
          title: `3.5. TRANSMISSION OF PERSONAL DATA`,
          p1: `Your data may be transmitted to subcontractors so that they can process it in the name and on behalf of Exportech Portugal companies.`,
          p2: `Subcontracted entities, as well as entities providing or licensors of services to Exportech Portugal companies, may have access to data collected and recorded by Exportech Portugal companies, when and to the extent necessary 
               for offering the Customer products or services sold by Exportech Portugal companies, or for fulfilling contractual obligations established between Exportech Portugal companies and the Customer.`,
        },
        text9: {
          title: `3.6. RECRUITMENT AND SELECTION`,
          p1: `By submitting a spontaneous application or application for an opportunity, you authorize your personal data collected to be processed and stored electronically by Exportech Portugal with the 
              for the purpose of being contacted for open recruitment and selection processes, or that may arise in the future, for Exportech Portugal companies.`,
          p2: `Your personal data is kept for 2 years after which it is deleted. You must submit a new application after this period if you want to continue to appear in the Exportech Portugal Database.`,
          p3: `If integrated into a recruitment and selection process, this process will be maintained for 5 years.`,
          p4: `The recipients or categories of recipients of your personal data are the Human Resources Department, the Administration, and other departments or functions relevant to the analysis of the application.`,
          p5: `Your personal data may also be shared with other entities when required by law or to respond to legal process, and also in situations that involve the protection of customers, the protection of lives, the security of services, the protection of property rights or Exportech Portugal companies.`,
          p6: `Recruitment processes do not involve automated decision-making, including profiling.`,
          p7: `No comparisons, interconnections or any other way of interrelating the recorded information are made.`,
          p8: `Consent is required for further processing of personal data for a purpose other than that for which the data was collected.`,
          p9: `Exportech Portugal only accepts applications from people aged at least 16, who have completed compulsory education and who have
           of physical and mental capabilities appropriate to the job.`
        },
        text10: {
          title: `3.7. ETHICS`,
          p1: `Exportech Portugal's code of ethics establishes the values ​​and ethical principles of Exportech Portugal's activities and
               how they should be reflected in our relationships with different stakeholders.`,
          p2: `To report bad ethical practices, you must consent to the processing of your personal data, for this purpose and as a way of safeguarding 
              risks of slanderous reports and discrimination. The absence of consent determines the non-processing of the communication and its deletion and the data contained therein.`,
              p3: `Your personal data will be deleted immediately if it proves to be inaccurate or useless; will be stored for 6 months after the end of the investigations if there is no disciplinary or judicial process and until the end of the 
              process if there is disciplinary or judicial proceedings. In this case, your personal data will be stored in an information system with restricted access for a period not exceeding the disciplinary or judicial process.`,
          p4: `Your personal data may also be shared with other entities when required by law or to respond to legal processes, and also in situations involving the protection of customers, protection of lives, security of services, protection of property rights or Exportech Portugal companies.`,
          p5: `Recruitment processes do not involve automated decision-making, including profiling. No comparisons, interconnections or any other form of interrelationship of recorded information are made.`,
        },
        text11: {
          title: `4. DEADLINES AND BASIS FOR THE PROCESSING AND STORAGE OF PERSONAL DATA`,
          p1: `Without prejudice to legal or regulatory provisions to the contrary, Personal Data will be stored/processed only by the
               minimum period necessary for the purposes that motivated its collection or subsequent processing.`,
          p2: `The subsequent processing of Personal Data for a purpose other than that for which the data was collected requires consent, before such processing the companies of the
               Exportech Portugal, request respective consent and provide the Customer with information regarding this purpose and any other pertinent information.`,
               p3: `Exportech Portugal will keep your personal data for as long as it maintains the contractual relationship established with the holder of the personal data.`,
               p4: `There are cases in which the law requires the processing and conservation of data for a minimum period of time.`,
               p5: `In the absence of a specific legal obligation, then the data will be processed/preserved only for the period of time necessary to fulfill the purposes that motivated its collection and preservation or, 
                    depending on what is applicable, until you exercise your right to object, right to be forgotten or withdraw consent and always in accordance with the law, the guidelines and decisions of the control authorities.`,
                    p6: `The Customer is guaranteed, under legal terms, the right to withdraw their consent at any time, without compromising the lawfulness of the processing carried out based on previously given consent and the right to request Exportech Portugal companies access to Personal Data that concern you, as well as their rectification 
                    or its erasure, and the limitation of processing with regard to the Customer, or the right to object to processing, as well as the right to data portability through written communication addressed to Exportech Portugal.`,
               p7: `Personal Data necessary for the execution of the contract to which the Customer is a party, for the fulfillment of legal obligations to which the Data Controller is subject and those necessary for the purposes of the legitimate interests pursued by Exportech Portugal companies are not are included in the previous point.`,
               p8: `After the conservation/processing period has elapsed, Exportech Portugal will eliminate or, if it is in the interest of Exportech Portugal companies, anonymize (in such a way that the Customer is not or can no longer be identified) the data whenever they should not be conserved for a distinct purpose that may subsist.`,
        },
        text12: {
          title: `4.1. BASIS FOR THE PROCESSING OF PERSONAL DATA`,
          p1: `Consent: when you have the prior and express consent of the data subject – in writing, orally or by validating an option – and if this consent is free, informed, specific and unambiguous.`,
          p2: `– special case of minors:`,
          p3: `In the case of processing personal data of minors, which may be subject to prior consent, this must be provided by the holders of parental responsibilities.`,
          p4: `Contract execution: when the processing of personal data is necessary for the conclusion, execution and management of the contract concluded with any of the Exportech Portugal companies.`,
          p6: `Compliance with legal obligation: when the processing of personal data is necessary to comply with a legal obligation to which Exportech Portugal is subject.`,
          p7: `Legitimate interest: when the processing of personal data corresponds to a legitimate interest of Exportech Portugal or third parties.`,
        },

        text13: {
          title: `5. COOKIES`,
          p1: `Exportech Portugal uses cookies on all our websites to improve performance and your user experience.`,
          p2: `The use of cookies by Exportech Portugal aims to analyze the use of the website and allow you to have a problem-free browsing experience; At no time do we collect Personal Data through cookies.`,
          p3: `Cookies will not be used for any purpose other than those mentioned in the previous point.`,
          p4: `Cookies are small text files that a website, when visited by the user, places on their computer or mobile device through the internet browser (browser). This information
          is used to record the number of visits made and compile statistical information about website activity.`,
          p5: `We use the term cookies in this Policy to refer to all files that collect information in this way.`,
          p6: `Types of Cookies depending on the entity that manages them:`,
          p7: `Own cookies: a cookie managed by this domain/website.`,
          p8: `Third-party cookies: a cookie managed by another domain/website.`,
          p9: `Persistent cookies: cookie information is permanently stored on your device.`,
          p10: `Session cookies: cookie information is not stored permanently on your device.`,


          p11: `Exportech Portugal uses session cookies (“session cookies”) and first-party cookies (“first-party cookies”) as well as persistent cookies (“persistent cookies”) and third-party cookies (“third-party cookies”). `,
          p12: `Types of cookies depending on the purpose of use:`,
          p13: `Technical cookies: these are those that allow you to navigate through a domain / website, using different options or services such as, for example, identifying the session, accessing restricted access areas and
          carry out the process of a purchase order.`,
          p14: `Personalization cookies: these are those that allow access to the domain / website with some predefined characteristics depending on a set of decisions made, such as the language used.`,
          p15: `Analysis cookies: are those that allow the monitoring and analysis of the behavior of users of websites to which they are linked. 
          The information collected through such cookies is used, for example, to measure website activity and create browsing profiles.`,
          p16: `Advertising cookies: are those that allow the most efficient management of advertising spaces based on criteria such as the edited content or the frequency with which advertisements are displayed.`,
          p17: `Behavioral advertising cookies: these are those that allow advertising spaces to be managed in the most efficient way possible. 
          These cookies store information about the user's behavior, obtained through continuous observation of their browsing habits, allowing us to develop a specific profile to display advertisements based on this.`,
        
          },
      },
      block2: {
        title_header: `Terms and Conditions`,
        text1: {
          title: `1. SCOPE OF APPLICATION`,
          p1: `1.1. These General Conditions establish the conditions applicable to sales made by Exportech, hereinafter referred to as the Company, and the Customer, hereinafter referred to as the Customer, and regulate the rights and obligations of the parties, prevailing over any non-mandatory legal provisions.`,
          p2: `1.2. Any exception or amendment to these General Conditions takes the form of Particular Conditions and will only be valid if formulated in writing, accepted and signed by the legal representatives of the parties, the latter prevailing over the former.`,
          p3: `1.3. Any conditions or specifications that the Customer may insert in documentation of any nature that are contradictory to the provisions of the General or Particular Conditions will be null and void. `,
        },
        text2: {
          title: `2. PRODUCT SELECTION`,
          p1: `2.1. The Customer is solely responsible for the selection of the product subject to purchase and sale, as well as the use or function for which it is intended. Therefore (and in accordance with what is stipulated in the catalogues, price tables and/or general information about the Company's product), the Company is not responsible for, nor does it guarantee that the product is suitable for the technical applications intended by the Customer, nor for the achievement, in whole or in part, of the objectives sought by him when making his purchase. The images contained in this catalog are merely illustrative and may differ from the original appearance of the
          equipment, therefore, colors, shapes and finishes are subject to possible changes, without prior notice.`,
        },
        text3: {
          title: `3. ORDERS`,
          p1: `3.1. On the date of ordering the goods and/or services that the Customer intends to purchase, the Customer is obliged to make the request in writing using the Company's form appropriate for that purpose, or via email to comecial@store.exportech.com. pt.`,
          p2: `3.2. The delivery time for the ordered goods, indicated by the Company, is merely indicative and in no case binding, and the Company is therefore not liable for any responsibility for delivery of the goods outside the deadline initially indicated.`, 
        },
        text4: {
          title: `4. INSPECTION OF GOODS`,
          p1: `4.1. All Goods are sold with the quality they possess and in the condition they are in at the date of sale. Customers may inspect the goods subject to sale, subject to prior agreement by the Company, considering that the Customer has examined the goods subject to sale or waived this right from the moment the respective purchase proposal is presented to the Company. `, 
        },
        text5: {
          title: `5. QUANTITIES`,
          p1: `5.1 Any differences in quantities in the sales of goods made per unit must be immediately communicated to the Company by the Customer, upon delivery or collection of the goods. In any case, the Company reserves the right to inspect said goods before accepting the claim made by the Customer. `,
        },
        text6: {
          title: `6. PAYMENT TERMS`,
          p1: `6.1. Unless otherwise agreed in writing, and without prejudice to what is stipulated in clause 3a, the price of the goods purchased by the Customer will be paid to the Company in cash, after issuing the respective invoice or equivalent document. Failure to pay results in the immediate termination of the contract and the Customer's default. `,
          p2: `6.2. The price of goods may be changed by the Company without prior notice. However, you will always be paid the value of the goods on the date of your order, or, if the sale was not preceded by an order, on the date of your payment.`,
          p3: `6.3. The prices established by the Company are subject to VAT calculated at the legal rate in force on the date of issuance of the payment invoice for the goods purchased by the Customer.`,
          p4: `6.4. The goods, even if in the possession of the Customer, continue to be the property of the Company until all of the Customer's obligations are fully fulfilled and the respective settlement of the goods in full.`,
          p5: `6.5.All sales not settled by the due date are subject to late payment interest at the average interest rate applied by Portuguese banks to overdrafts on current accounts calculated on the date of payment due.`,
        },
        text7: {
          title: `7. PROVISION, DELIVERY AND COLLECTION OF GOODS,`,
          p1: `7.1. The goods will be considered made available to the Customer at the location defined by the Company, unless otherwise agreed in writing.`,
          p2: ` 7.2. If the goods are delivered to a location defined by the Customer, shipping costs will be the responsibility of the Customer, and the risk is borne by the Customer.`,
          p3: `7.3. The Customer must collect the purchased goods within 3 (three) business days after payment.`,
          p4: `7.4. If the Customer does not make any payment when due or does not carry out any other obligations assigned to it in a timely manner, the Company may suspend the fulfillment of its duties, in particular, by limiting or ceasing technical support.`,
        },
        text8: {
          title: `8. TECHNICAL SUPPORT`,
          p1: `8.1. The Customer may at any time request services from the Company's Technical Department, which will be provided and charged under the conditions and terms to be defined by the Company. `,
          p2: `8.2. Any cost inherent to the Technical Support Service will be previously communicated to the Customer and accepted by the Customer in writing.`,
        },
        text9: {
          title: `9. COMPLAINTS`,
          p1: `9.1. The claim period is 8 (eight) days and must always be justified by the Customer and accompanied by the goods that motivated the claim in the same condition in which they were sold. `,
          p2: `9.2. The customer must request the Company to assign a repair/return number (RMA) that will identify the product, and without which it will not be possible to analyze it by the Company's Technical Department.`,
          p3: `9.3. Complaints will only be accepted after a favorable opinion from the Company's Technical Department, which will determine whether the equipment in question will need to be repaired or replaced, as decided by the Company.`,
          p4: `9.4. The Technical Department's opinion will be made within a maximum period of 15 (fifteen) business days, after receipt of the material at the Company's headquarters.`,
          p5: `9.5. Exchanges or returns will only be accepted if the equipment is proven to be defective.`,
          p6: `9.6. All equipment sent for repair or repair quotes will only be accepted when accompanied by a duly completed RMA form (available on the Company's website).`,
        },
        text10: {
          title: `10. REPAIRS`,
          p1: `10.1. At the Customer's request and request, and after contact with the Company's Technical Department, equipment purchased and fully paid for by the Customer may be repaired at the Company's headquarters.`,
          p2: `10.2. The Customer must request the Company to assign a repair/return number (RMA) that will identify the product, and without which it will not be possible to analyze it by the Company's Technical Department.  `,
          p3: `10.3. The equipment:`,
          p4: `10.3.1. After exhaustive analysis, no anomaly was detected.`,
          p5: `10.3.2. No detailed description of the fault.`,
          p6: `10.3.3. In cases where the proposed repair budget was not accepted, they will be subject to payment of an equipment analysis fee corresponding to 15% of the new value of the same equipment. `,
          p7: `10.3.4. The Company excludes any and all liability arising from the transport of equipment to be repaired.`,
        },
        text11: {
          title: `11. ELEMENTS OF FORCE MAJEURE RELATING TO DELIVERY`,
          p1: `11.1. After the conclusion of the Contract, the Company will not be responsible for any delays in making the goods subject to the Contract available to the Customer, if such delay is due to force majeure, unforeseeable circumstances or, in general, to situations that could not reasonably be foreseen or avoidable.`, 
        },
        text12: {
          title: `12. LIMITS OF THE COMPANY’S LIABILITY,`,
          p1: `12.1. In the event that, for any reason, there is a delay or lack of delivery of the goods, a defect in quantity, gender or any inaccuracy in 
          the description of the goods subject to the Contract, even if due to the fault or negligence of the Company, the liability of the latter will not, under any circumstances, 
          cover indirect damages suffered by the Customer, such as the loss of a resale operation, business or other similar profits or loss of profits.`, 
        },
        text13: {
          title: `13. RESOLUTION`,
          p1: `13.1. The Company has the right to terminate, in whole or in part, the Contract with immediate effect in the following cases: `,
          p2: `13.1.1. Failure to comply with the General and/or Particular Conditions;`,
          p3: `13.1.2. The subjection to any insolvency process, judicial or extrajudicial liquidation of the Customer, the reduction of the guarantees provided or, in general, any modification of its legal structure that affects its solvency;`,
          p4: `13.1.3. Termination, for whatever reason, even if only de facto cessation, of the Customer's activity;',`,
          p5: `13.1.4. Occurrence of any other causes of termination provided for in the Contract or the Law.`, 
        },
        text14: {
          title: `14. REDUCTION OF THE CONTRACT`,
          p1: `14.1. The invalidity, total or partial, of any provision of the Contract, will not affect the validity of the remaining provisions,    unless the interested party demonstrates that the purpose pursued by the Company and the Customer allows us to assume that they would not have concluded the transaction without the invalid party.` ,
        },
        text15: {
          title: `15. PENAL CLAUSE`,
          p1: `In the event of late payment of an invoice and its continuation after the Customer has been requested to pay by the 
          Company, the Customer must pay the Company, in addition to the amount relating to the principal debt and respective interest, an amount corresponding to 15% of the amount owed, as a penalty clause.`,
        },
        text16: {
          title: `16. COMPETENT JURISDICTION`,
          p1: `To resolve any issue arising from this contract, the parties agree to the Lisbon District Court.`, 
        },
      },
      block3: {
        title_header: `Exchanges and Returns Policy`,
        subtitle:"CONDITIONS OF ACCEPTANCE",
        text1: {
          numb:"1.",
          txt:`RMAs must always be accompanied by this Form;`
        },   
        text2: {
          numb:"2.",
          txt:`All Accessories that accompany the RMA must be expressly indicated, otherwise requests for accessories not indicated will not be accepted later. If accessories are indicated that are not included with the equipment, the RMA will be returned and the transportation costs will be borne by the Customer;`
        },   
        text3: {
          numb:"3.",
          txt:`RMA's that do not reveal any damage will be dispatched with the transport costs being borne by the Customer and a fee of €15.00 will be charged for the Assistance provided;`
        },
        text4: {
          numb:"4.",
          txt:`When in the Warranty Period, the Customer bears the transportation costs to Exportech and Exportech bears the shipping costs to the customer;`
        },   
        text5: {
          numb:"5.",
          txt:`Credit requests must be made within a maximum period of 14 Business Days after the Invoice is issued, and from that date onwards they are subject to Assessment and Acceptance by Exportech Portugal;`
        },   
        text6: {
          numb:"6.",
          txt:`Only Products will be accepted for Credit whose verification by our Technical Services verifies that the 4 Assumptions above are validated (Normal Operation | Complete Package | Box in Good Condition | Sellable Equipment);`
        },   
        text7: {
          numb:"7.",
          txt:`Equipment that is not collected or whose Repair Payment is not paid within 60 Days will revert to Exportech Portugal;`
        },   
        text8: {
          numb:"8.",
          txt:`All equipment outside the warranty period is subject to a fee of €15.00 for the respective analysis. This value will be diluted in the cost of the Repair if the Budget is accepted;`
        },
        text9: {
          numb:"9.",
          txt:`All Repairs benefit from a 3 Month Warranty;`
        },   
        text10: {
          numb:"10.",
          txt:`All equipment for credit notes must mention the equipment password in the field above. If you forget or lose your password(s), Exportech will charge €25+VAT for the single service.`,
        },   
        text11: {
          numb:"11.",
          txt:`Issuance of Credit Notes upon return of equipment by customers: Up to 15 days* – No devaluation 16 – 31 days* – 15% devaluation 32 – 60 days* – 40% devaluation *(assuming that the equipment is perfectly salable, original packaging, free from risks and/or use, accessories, etc.); After 61 days – Exportech does not accept returns.`
        },   
        text12: {
          numb:"12.",
          txt:`12. Project Products or Order intended for a project (Non-Stock Products) – Exportech does not accept returns;`
        },
      }, 
    }, 

    about_locations: {
      l1:`Exportech Lisbon Headquarters`,
      l2:`Exportech Funchal Branch`,
      l3:`Logistics Warehouse`,
   },
  }
}

const SPANISH = {
  translations: {
    navbar: {
      link1: 'Comienzo',
      link2: `Productos`,
      link3: `Servicios`,
      link4: `Contactos`,
      link5: `Tienda`,
      lang1: `Portugués`,
      lang2: `Inglés`,
      lang3: `Español`,
    },
    header: {
      description: `Somos una empresa extremadamente dinámica, formada por profesionales con know-how y motivados por los proyectos en los que nos involucramos. Somos y pretendemos ser cada vez más un referente creciente en las áreas en las que operamos.`,
      button: `Descubra más`
    },
    home: {
      home_text_area1: {
        title: `Tu Aliado en seguridad`,
        text1: `El éxito que nos acompaña desde nuestra fundación en`,
        text2: ` 2006 `,
        text3: `ha sido posible porque contamos con una equipa de profesionales altamente motivados,`,
        text4: ` una planificación estratégica bien definida `,
        text5: ` y socios de excelencia que caminan a nuestro lado, consolidando a Exportech Portugal como una referencia cada vez mayor en el mercado.`,
        text6: ` Marcas de prestígio, equipos confiables, excelente soporte técnico e comercial, flexibilidad de negociación e inmejorable apoyo financiero.  `,
        text7: "#SomosExportech#1"
      },
      circles_text: {
        text1: `Marcas innovadoras`,
        text2: `Flexibilidad y adaptabilidad`,
        text3: `Calidad del equipo`,
      },
      home_text_area2: {
        small: `Apoyamos incondicionalmente a quien nos ayuda a crecer `,
        title: `Un equipo de profesionales listos para ayudar.`,
        text1: `Exportech Portugal dispone de un área técnica con las más recientes tecnologías, que nos
        permiten prestar Soporte Técnico Remoto a nuestros socios/aliados (discutir mejor opción), ayudándolos a solucionar los problemas del día a día.`,
        text2: `Ya sea de manera remota o presencial, asesoría en obra o formación en nuestras oficinas,
         estamos listos para ayudarle a crecer, dotando a su equipo técnico del know-how necesario para ser autónomos con las marcas que distribuimos.`,
        text3: `Podrá solicitar formación personalizada siempre que lo considere necesario en la  `,
        text4: ` Academia Exportech`,
        text5: `permitiendo la programación de la misma de forma autónoma.`,
        text6: `Podrá solicitar formación personalizada siempre que lo considere necesario en la  `,
        text7: `Academia Exportech `,
        text8: `permitiendo la programación de la misma de forma autónoma.`,
      },
      boxes_area: {
        box1: {
          title: `Soporte Técnico`,
          description: `Contactenos y obtenga asistencia técnica remota por parte de nuestros técnicos.`,
        },
        box2: {
          title: `Soporte Presencial`,
          description: `Agende con uno de nuestros técnicos y reciba asistencia en persona.`
        },
        box3: {
          title: `Formulario RMA`,
          description1: `Descargar `,
          description2:"Formulario RMA",
          description3:" , complételo correctamente y envíelo a geral@exportech.com.pt para su rápido procesamiento."
        },
        box4: {
          title: `Contactos`,
        },
      },
      home_text_area3: {
        description: `Les prometemos competencia, competitividad e innovación para dotarles de las herramientas
         necesarias para crecer junto a nosotros. Ofrecemos un acompañamiento comercial y técnico cercano, así como un
          catálogo de soluciones completo y diversificado.`,
        text1: `Desarrolo`,
        text2: `Soluciones integradas de seguridad y Software de Gestión de Asistencia Cloud 2Smart HRM`,
        text3: `Crecimiento`,
        text4: `Hemos crecido continuamente desde 2006 gracias a sólidas asociaciones.`,
        text5: `Marcas`,
        text6: `Representamos a 30 Fabricantes de Prestígio`,
        text7: `Clientes`,
        text8: `Contamos con 1.500 socios-aliados que nos ayudan a crecer cada año.`,
      },
      home_text_area4: {
        small: `Porque a Exportech Portugal`,
        title: `Lo que nos diferencia`,
        description: `Nuestra catalogo de Soluciones de Seguridad e Networking ahora está más 
        completo que nunca. Diversidad, Fiabilidad e Competitividade`,
        text1: `Innovación`,
        text2: ` Línea Estratégica`,
        text3: `Innovamos continuamente en productos y procesos para ser mañana mejores de lo que somos hoy. La innovación forma parte de nuestro ADN.`,
        text4: `Solidez`,
        text5: `Crecimiento Anual`,
        text6: `Hemos crecido de manera sólida desde 2006, reflejando una estrategia exitosa.`,
        text7: `Honestidad`,
        text8: `Postura rigurosa`,
        text9: `Una postura rigurosa de transparencia con nuestros socios, una buena gestión de expectativas, 
        información constante y disponibilidad siempre garantizada.`,
        text10: `Flexibilidad`,
        text11: `Apoyo incondicional`,
        text12: `Flexibilidad en la negociación y en el apoyo financiero. Una estrategia debe ser flexible y adaptable a los 
        constantes cambios del mercado y a cada socio.`,
        text13: `Calidad`,
        text14: `Rigurosa selección de marcas`,
        text15: `Calidad a un precio competitivo. Seleccionamos rigurosamente las marcas y productos que distribuimos, 
        garantizando altos estándares de calidad.`,
        text16: `DESCUBRE MÁS`,
      },
      home_text_area5: {
        small: `Hemos logrado mucho, pero ambicionamos más`,
        title: `Logros a lo largo de los años`,
        description: `17 años de aprendizaje, cientos de proyectos diseñados junto a nuestros socios, 
        momentos de convivencia y amistad, y muchas sonrisas nos han llevado hasta aquí.`,
        text1: `4.4M€`,
        text2: `de Facturación en 2023`,
        text3: `3,000M2`,
        text4: `de Área Logística`,
        text5: `30`,
        text6: `Fabricantes Representados`,
        text7: `17`,
        text8: `años de aprendizaje`,
      },
      home_text_area6: {
        small: `Todos los caminos conducen al éxito.`,
        title: `Conviértase en nuestro socio`,
        description: `Un socio sólido y de confianza, siempre disponible para apoyarlo en todos los aspectos.`,
        description2: `Regístrese en nuestro sitio web y obtenga acceso a un portafolio extremadamente diversificado y competitivo.`,
        text1: `Vamos a crecer juntos ?`,
        button: `Regístrese en el sitio web`
      },
    },
    footer: {
      text1: "Sed Lisboa",
      text2: "Sucursal de Funchal",
      text3: "Almacén logístico",
      text4: "Navegación",
      text5: "Tienda en línea",
      text6: "Cuenta de cliente",
      text7: "Acerca de nosotros",
      text8: "Políticas de privacidad",
      text9: "Libro de quejas",
    },
    about: {
      title_header: `Conozca mas sobre nosotros`,
      about_text_area1: {
        small: `Nosotros somos`,
        title: `Lider en la Distribuicion de Tecnologias de Seguridad y Networking`,
        text1: `Nacimos en 2006 e somos orgullosamente una empresa de Capital 100% Portugues.`,
        text2: `Nuestros pilares fundamentales se basan en la innovación constante de nuestras soluciones, en la honestidad e 
        integridad en las relaciones que establecemos con nuestros socios, en un soporte técnico excepcional, y en el compromiso 
        y la dedicación que asumimos con quienes nos ayudan a crecer.`,
        text3: `El éxito de nuestra estrategia se basa en un equipo de profesionales de excelencia, 
        con pensamiento innovador, ambiciosos y siempre disponibles para nuestros socios, una selección criteriosa de fabricantes 
        y soluciones tecnológicas innovadoras.`,
        text4: `Contamos con un catalogo compuesto por 6.000 artículos con una alta disponibilidad de stock, tres almacenes con 
        una capacidad logística combinada de 3.000 m² y 30 fabricantes representados.`,
        text5: `Estamos llenos de energía que nos permite aspirar a la continuidad de nuestro crecimiento. ¡Estamos listos para más!`,
        text6: `Estamos llenos de energía que nos permite aspirar a la continuidad de nuestro crecimiento.`,
        button: `Ponte en contacto`
      },
      about_text_area2: {
        small: "Nuestro negocio",
        title: `Se destaca del resto`,
        text1: `Nuestra misión`,
        text2: ` Identificar y distribuir soluciones tecnológicas de seguridad electrónica`,
        text3: `Nuestros Valores Fundamentales `,
        text4_1: `Innovación`,
        text4_2: `Integridad`,
        text4_3: `Honestidad`,
        text4_4: `Compromiso`,
        text4_5: `Flexibilidad`,
        text5: `  Nuestra visión  `,
        text6: `Esforzarnos constantemente por mejorar nuestras soluciones. Mañana mejores que hoy.`,
        link: `Ponte en contacto`
      },
      about_text_area3: {
        small: `Diferentes lugares pero con el mismo objetivo`,
        title: `Descubra donde estamos ubicados`,
        link: `Ponte en contacto`,
        text1: `Exportech - Sede  Lisboa`,
        text2: `Exportech  - Filial Funchal`,
        text3: `Exportech - Armazém Logístico`,
      },
      about_text_area4: {
        small: `Company Profile`,
        title: `Somos diferentes en muchos aspectos.`,
        description: `No es posible resumir toda la representación de una empresa
       en simples números y puntos, pero a continuación se presentan nuestras cifras para 2023.`,
        description2: `A su lado, ¡prometemos seguir creciendo!`
      },
      about_text_area5: {
        small: `Equipa Dedicada. `,
        title: `Una Equipa compuesta por profesionales de Excelencia. `,
        description: `Somos una Equipa altamente motivada e con el enfoque en los resultados. #SomosExportech`
      },
    },
    products: {
      title_header: `Productos fascinantes`,
      products_text_area1: {
        title: `Tu Nueva Plataforma de Gestión de Asistencias Cloud`,
        text1: `Imaginado con detalle, planeado con rigor, y diseñado para simplificar - El 2Smart ha
         revolucionado el mercado con su enfoque innovador y visión fuera de la caja. La gestión de 
         asistencias suele ser una tarea tediosa, y las plataformas existentes se basan en procesos 
         obsoletos que no evolucionan ni se centran en las necesidades tanto de los usuarios como de
        los gestores de RH.`,
        text2: `El 2Smart HRM simplifica esta tarea con procesos de configuración completos pero 
        simples y versátiles, y con diseños innovadores que facilitan la percepción y realización de 
        las tareas relacionadas.`,
        text3: `El Poder Está en Ti...`,
        text4: `Simplifique la gestión de recursos humanos con 2Smart HRM`,
        button: `Más información`
      },
      products_text_area2: {
        small: `Líder en Innovación y Competitividad`,
        title: ` Contamos con un catalogo inigualable. Ven a conocer en detalle nuestras soluciones.`,
        text0: `Productos CCTV`,
        text1: `Cables`,
        text2: `Redes`,
        text3: `Fibra óptica`,
        text4: `Sistemas contra incendios`,
        text5: `Videoportero`,
        text6: `Accesorios`,
        text7: `Sistemas de intrusión`,
        text8: `EAS/Antirrobo`,
        text9: `Torniquetes`,
        text10: `Detección de metales`,
        text11: `Detección de gases`,
        text12: `Sistemas de emergencia`,
        text13: `Soluciones de estacionamiento`,
        link: `Visita nuestra tienda online`,
      },
    },
    contacts: {
      title_header: `Contactos`,
      contacts_text_area1: {
        small: `Nuestros contactos`,
        title: `Como entrar en contacto con nuestro equipo de especialistas`,
        description: `Envie un mensaje através del formulario que hemos proporcionado.
        Si su consulta es urgente, utilize los contantos que aparecen a continuación.`,
        text1: `Exportech Portugal - Sede`,
        text2: `Consultas Generales`,
        text3: `Teléfono`,
        text4: `Horario de apertura`,
        text5: `Lunes - Viernes: 9 am a 6 pm (sábado y domingo: cerrado)`,
      },
      contacts_text_area2: {
        title: `Somos un equipo extremadamente motivado y enfocado en resultados. ¿Trabajamos juntos?`,
      },
      contacts_text_area3: {
        small: `lugares diferentes pero con el mismo objetivo`,
        title: `Conozca dónde estamos ubicados`,
        link: `Ver en mapa`
      },
    }, 

    Terms: {
      block1: {
        title_header: `Política de Privacidad`,
        text1: {
          title: `1. COMPROMISO`,
          p1: `La protección de la privacidad de los usuarios y de sus datos personales constituye un compromiso fundamental de Exportech Portugal.`,
          p2: `Exportech Portugal le recomienda leer esta Política de Privacidad.`,
          p3: `Esta Política de Privacidad fue redactada de manera clara y sencilla, para que usted pueda comprenderla más fácilmente y decidir de manera informada. 
            De forma libre y voluntaria si desea facilitar sus datos personales y explicar qué datos personales Exportech Portugal 
            recopila a través de interacciones con usted y cómo se utilizan esos datos. `, 
        },
        text2: {
          title: `2. ÁMBITO DE APLICACIÓN, RESPONSABLE DEL TRATAMIENTO DE DATOS Y RESPONSABLE DE LA PROTECCIÓN DE DATOS`,
          p1: `Esta Política de Privacidad se aplica a los datos personales de nuestros usuarios, clientes, proveedores, candidatos y otras partes interesadas.`,
          p2: `El responsable de recopilar y procesar sus datos personales son las empresas Exportech Portugal que le brindan productos y/o servicios y que, en este ámbito, decide qué datos se recopilan, los medios de procesamiento y los fines para los cuales se recopilan. usado.` ,
          p3: `Exportech Portugal ha designado un Delegado de Protección de Datos (DPO), que supervisa el cumplimiento del procesamiento de datos con las normas aplicables,
          es un punto de contacto con el usuario para aclarar cuestiones relativas al tratamiento de sus datos, coopera con las autoridades de control y proporciona información y asesora al responsable o subcontratista sobre sus obligaciones en materia de privacidad y protección de datos`,
        },
        text3: {
          title: `3. DATOS PERSONALES, TITULARES DE LOS DATOS PERSONALES Y CATEGORÍAS DE DATOS PERSONALES`,
          p1: `«Datos Personales», se refiere a información relativa a una persona física identificada o identificable («Titular de los Datos»); Se considera persona física identificable una persona física que puede identificarse, directa o indirectamente, en particular mediante un identificador, como un nombre, un número de identificación, datos de ubicación, identificadores electrónicos o uno o más. 
            elementos específicos de la identidad física, fisiológica, genética, mental, económica, cultural o social de esa persona física;»,`,
          p2: `«Titular de los datos» es la persona física a quien se refieren los datos;`,
          p3: `Esta Política le permite saber cómo Exportech Portugal recopila y utiliza sus Datos personales, cómo puede controlar su uso y describe nuestras prácticas en relación con la información recopilada en todos los sitios web (incluidos, entre otros, aplicaciones de software, redes sociales). y mensajes electrónicos) de Exportech Portugal, así como actividades de marketing y ventas fuera de línea (colectivamente, “Canales de Comunicación”). Los Canales de Comunicación pueden proporcionar enlaces a otras plataformas que no pertenecen a Exportech Portugal. Le recomendamos que consulte las políticas de privacidad de estos sitios web, servicios, redes sociales o aplicaciones antes de proporcionar sus Datos Personales, y no somos responsables del procesamiento de los datos personales que puedan ser proporcionados y/o recopilados en los mismos.`,
          p4: `Confiamos en que nos proporcione información precisa y completa sobre sus datos personales y que los actualice en relación con cualquier cambio en la información proporcionada.`,
        },
        text4: {
          title: `3.1. ¿CUÁNDO Y QUÉ INFORMACIÓN RECOPILAMOS SOBRE USTED? `,
          p1: `Recopilamos tus datos personales, con tu consentimiento, cuando te registras en nuestro sitio web o realizas un pedido de compra de productos o servicios. También recopilamos sus datos personales cuando completa voluntariamente cuestionarios, formularios o utiliza herramientas de chat.`,
          p2: `Le solicitamos que no envíe ni comparta con Exportech Portugal ninguna información personal sensible como origen racial o étnico, opiniones políticas, convicciones religiosas o filosóficas, o afiliación sindical, así como datos genéticos, datos biométricos, relacionados con salud o datos relativos a la vida o la orientación sexual.»`,
          p3: `La información de uso del sitio web se recopila mediante cookies, pero no lo identifican específicamente, y se recopilan o pueden recopilarse los siguientes datos:`,
          p4: `Dirección IP.`,
          p5: `Ubicación geográfica.`,
          p6: `Información sobre el dispositivo que accede al sitio web, es decir, tipo y versión del navegador, sistema operativo, resolución de pantalla, idioma preferido, información contenida en los encabezados HTTP y versión del agente.`,
          p7: `El motor de búsqueda que utilizó para encontrar y acceder al sitio web.`,
          p8: `Fecha y hora de actividad en el sitio web, así como páginas web y contenidos visitados y en los que se hizo clic.`,
          p9: `Es importante resaltar que esta información de uso no lo identifica deliberadamente. y si es excepcional (la identificación será inmediatamente anónima.`,
          p10: `Al facilitar información estás dando tu consentimiento informado e inequívoco que consientes el tratamiento de los datos que te conciernen de forma automatizada. El mencionado consentimiento previo y expreso del interesado se prestará por escrito, de forma oral o mediante validación. de una opción.`,
          p11: `Algunos de los datos solicitados son obligatorios y están debidamente marcados, y la falta o inexactitud de las respuestas dadas puede comprometer el servicio prestado.`,
       
        },
        text5: {
          title: `3.2. ¿CÓMO UTILIZAMOS LA INFORMACIÓN SOBRE USTED?`,
          p1: `Los datos recopilados se utilizan para procesar su pedido, administrar su cuenta y, si está de acuerdo, enviarle información sobre otros productos y servicios por correo electrónico.`,
          p2: `Utilizamos la información recopilada del sitio web para personalizar sus próximas visitas a nuestro sitio web.`,
          p3: `Si está de acuerdo, enviaremos su información personal a las distintas empresas de Exportech Portugal para que puedan ofrecerle sus productos y servicios. `,
          p4: `Exportech Portugal no comparte su información para campañas de marketing con empresas ajenas al grupo ni transfiere sus Datos Personales a un tercer país.`,
          p5: `Aunque la información proporcionada es generalmente tratada como privada en Exportech Portugal, los datos recopilados serán utilizados de acuerdo con la normativa de protección de datos personales, durante todo el proceso. 
          de tratamiento; Sin embargo, puede ser necesario transferir datos personales de forma privada a otros organismos, en particular el poder judicial, y/o utilizar información de agencias de crédito/compañías de seguros o similares.`,
          p6: `Tiene derecho a presentar una queja ante una Autoridad de Control Nacional y/o de la Unión Europea en relación con cualquier violación de sus Datos Personales. 
          Exportech Portugal prestará su colaboración a la Autoridad de Control facilitándole toda la información que esta le solicite, en el ejercicio de sus competencias. `,
          p7: `Exportech Portugal sólo acepta registros de usuarios que tengan al menos 16 años. Los Canales de Comunicación de Exportech Portugal no están dirigidos
               a usuarios menores de edad. Si tiene conocimiento de algún registro realizado por un menor de edad, le agradeceríamos que nos avisara para que podamos eliminar el registro de inmediato. `,
          p8: `Los destinatarios o categorías de destinatarios de sus Datos Personales, dependiendo de la herramienta de contacto que utilice, son los departamentos involucrados en la relación comercial, 
              los departamentos de Marketing, el Departamento de Personas y otros departamentos de las diferentes empresas de Exportech Portugal.`,
          p9: `Cada vez que transfiere o descarga un paquete de software en cualquier sitio web de cualquier empresa Exportech Portugal, puede estar en contacto con los objetivos
              supervisar la experiencia de uso y presentación del software en cuestión y/u otros productos similares.»`,
          p10: `Decisiones automatizadas: `, 
          p11: `La información recopilada en los sitios web de Exportech Portugal no implica la toma de decisiones automatizada, incluida la definición de perfiles.  `,
          p12: `Sus datos pueden circular en una red abierta y correr el riesgo de ser vistos y utilizados por terceros no autorizados.`,
          p13: `En los sitios web de Exportech Portugal no se realizan comparaciones, interconexiones o cualquier otra forma de interrelacionar la información registrada. `,
        },
        text6: {
          title: `3.3. COMERCIALIZACIÓN`,
          p1: `Nos gustaría poder enviarle información sobre los productos y servicios de las empresas de Exportech Portugal que puedan ser o puedan ser de su interés. si sucediera 
          Con tu consentimiento previamente siempre podrás optar por darte de baja. Tienes derecho en cualquier momento a no 
          permitirle ser contactado para campañas de marketing. `,
        },
        text7: {
          title: `3.4. CONDICIONES GENERALES DE SUMINISTRO`,
          p1: `Las condiciones generales de suministro de Exportech Portugal están disponibles en los sitios web de Exportech Portugal.`,
        },
        text8: {
          title: `3.5. TRANSMISIÓN DE DATOS PERSONALES`,
          p1: `Sus datos podrán ser transmitidos a subcontratistas para que puedan procesarlos en nombre y por cuenta de las empresas de Exportech Portugal.`,
          p2: `Las entidades subcontratadas, así como las entidades que prestan o otorgan licencias de servicios a las empresas de Exportech Portugal, pueden tener acceso a los datos recopilados y registrados por las empresas de Exportech Portugal, cuando y en la medida necesaria 
               para ofrecer al Cliente productos o servicios vendidos por las empresas de Exportech Portugal, o para cumplir con las obligaciones contractuales establecidas entre las empresas de Exportech Portugal y el Cliente.`,
        },
        text9: {
          title: `3.6. RECLUTAMIENTO Y SELECCIÓN`,
          p1: `Al presentar una solicitud espontánea o una solicitud de oportunidad, usted autoriza que sus datos personales recopilados sean procesados ​​y almacenados electrónicamente por Exportech Portugal con el 
              con el fin de ser contactados para procesos abiertos de reclutamiento y selección, o que puedan surgir en el futuro, para empresas de Exportech Portugal.`,
          p2: `Sus datos personales se conservan durante 2 años, después de los cuales se eliminan. Deberá presentar una nueva solicitud después de este período si desea seguir apareciendo en la base de datos de Exportech Portugal.`,
          p3: `Si se integra en un proceso de reclutamiento y selección, este proceso se mantendrá durante 5 años.`,
          p4: `Los destinatarios o categorías de destinatarios de sus datos personales son el Departamento de Recursos Humanos, la Administración, y otros departamentos o funciones relevantes para el análisis de la solicitud.`,
          p5: `Sus datos personales también podrán ser compartidos con otras entidades cuando así lo requiera la ley o para responder a un proceso legal, y también en situaciones que involucren la protección de clientes, la protección de vidas, la seguridad de los servicios, la protección de los derechos de propiedad. o empresas Exportech Portugal.`,
          p6: `Los procesos de contratación no implican la toma de decisiones automatizada, incluida la elaboración de perfiles.`,
          p7: `No se realizan comparaciones, interconexiones o cualquier otra forma de interrelacionar la información registrada.`,
          p8: `Se requiere consentimiento para el procesamiento posterior de datos personales para un fin distinto de aquel para el cual se recopilaron los datos`,
          p9: `Exportech Portugal sólo acepta solicitudes de personas que tengan al menos 16 años, que hayan completado la educación obligatoria y que tengan las capacidades físicas y mentales adecuadas para el trabajo.`
        },
        text10: {
          title: `3.7. ÉTICA`,
          p1: `El código de ética de Exportech Portugal establece los valores y principios éticos de las actividades de Exportech Portugal y
               cómo deberían reflejarse en nuestras relaciones con las diferentes partes interesadas.`,
          p2: `Para denunciar malas prácticas éticas, deberá consentir el tratamiento de sus datos personales, con esta finalidad y como forma de salvaguarda 
              riesgos de denuncias difamatorias y discriminación. La falta de consentimiento determina la no tramitación de la comunicación y su supresión de los datos contenidos en la misma.`,

              p3: `Sus datos personales serán eliminados inmediatamente si resultan inexactos o inútiles; serán almacenados durante 6 meses después del final de las investigaciones si no existe un proceso disciplinario o judicial y hasta el final de las 
              proceso si existe procedimiento disciplinario o judicial. En este caso, sus datos personales serán almacenados en un sistema de información de acceso restringido por un período que no excederá el proceso disciplinario o judicial.`,
              p4: `Sus datos personales también podrán ser compartidos con otras entidades cuando así lo exija la ley o para responder a procesos legales, y también en situaciones que impliquen la protección de clientes, protección de vidas, seguridad de servicios, protección de derechos de propiedad o empresas de Exportech Portugal .`,
              p5: `Los procesos de contratación no implican la toma de decisiones automatizada, incluida la elaboración de perfiles. No se realizan comparaciones, interconexiones ni cualquier otra forma de interrelación de la información registrada.»`
        },
        text11: {
          title: `4. PLAZOS Y BASE PARA EL TRATAMIENTO Y ALMACENAMIENTO DE DATOS PERSONALES`,
          p1: `Sin perjuicio de disposiciones legales o reglamentarias en contrario, los Datos Personales serán almacenados/procesados ​​únicamente por el
               plazo mínimo necesario para los fines que motivaron su recogida o posterior tratamiento.».`,
          p2: `El procesamiento posterior de Datos Personales para una finalidad distinta a aquella para la cual fueron recopilados requiere el consentimiento, antes de dicho procesamiento las empresas del
               Exportech Portugal, solicitará el consentimiento respectivo y proporcionará al Cliente información relativa a este fin y cualquier otra información pertinente.`,
          p3: `Exportech Portugal conservará sus datos personales mientras mantenga la relación contractual establecida con el titular de los datos personales.`,
          p4: `Hay casos en los que la ley exige el tratamiento y conservación de los datos durante un período mínimo de tiempo.`,
         
          p5: `En ausencia de una obligación legal específica, los datos serán procesados/conservados únicamente durante el período de tiempo necesario para cumplir con los fines que motivaron su recolección y conservación o, 
          según sea aplicable, hasta que ejerza su derecho de oposición, derecho al olvido o retirar el consentimiento y siempre de conformidad con la ley, las directrices y decisiones de las autoridades de control.`,
          p6: `El Cliente tiene garantizado, en términos legales, el derecho a retirar su consentimiento en cualquier momento, sin comprometer la licitud del procesamiento realizado sobre la base del consentimiento previamente otorgado y el derecho a solicitar a las empresas Exportech Portugal el acceso a los Datos Personales que le conciernen. usted, así como su rectificación 
          o su supresión, y la limitación del tratamiento respecto del Cliente, o el derecho de oposición al tratamiento, así como el derecho a la portabilidad de los datos mediante comunicación escrita dirigida a Exportech Portugal.`,
          p7: `Los Datos Personales necesarios para la ejecución del contrato del que el Cliente es parte, para el cumplimiento de las obligaciones legales a las que está sujeto el Responsable del Tratamiento y aquellos necesarios para los fines de los intereses legítimos perseguidos por las empresas de Exportech Portugal no son están incluidos en el punto anterior.`,
          p8: `Después de transcurrido el período de conservación/procesamiento, Exportech Portugal eliminará o, si es de interés de las empresas de Exportech Portugal, anonimizará (de tal manera que el Cliente no sea o ya no pueda ser identificado) los datos siempre que no deben conservarse para un fin distinto que pueda subsistir.`,
        },
        text12: {
          title: `4.1. BASE PARA EL TRATAMIENTO DE DATOS PERSONALES`,
          p1: `Consentimiento: cuando se cuenta con el consentimiento previo y expreso del interesado –por escrito, oralmente o validando una opción- y si este consentimiento es libre, informado, específico e inequívoco.`,
          p2: `– caso especial de menores:`,
          p3: `En el caso de tratamientos de datos personales de menores de edad, que puedan estar sujetos a consentimiento previo, éste deberá ser proporcionado por los titulares de la responsabilidad parental.`,
          p4: `Ejecución del contrato: cuando el tratamiento de datos personales sea necesario para la celebración, ejecución y gestión del contrato celebrado con cualquiera de las empresas de Exportech Portugal.`,
          p6: `Cumplimiento de obligación legal: cuando el tratamiento de datos personales es necesario para cumplir con una obligación legal a la que está sujeto Exportech Portugal.`,
          p7: `Interés legítimo: cuando el tratamiento de datos personales corresponde a un interés legítimo de Exportech Portugal o de terceros.`,
        },
        text13: {
          title: `5. GALLETAS`,
          p1: `Exportech Portugal utiliza cookies en todos nuestros sitios web para mejorar el rendimiento y su experiencia de usuario.`,
          p2: `El uso de cookies por parte de Exportech Portugal tiene como objetivo analizar el uso del sitio web y permitirle tener una experiencia de navegación sin problemas; En ningún momento recogemos Datos Personales a través de cookies.`,
          p3: `No se utilizarán cookies para ninguna finalidad distinta a las mencionadas en el punto anterior.`,
          p4: `Las cookies son pequeños archivos de texto que un sitio web, cuando es visitado por el usuario, coloca en su computadora o dispositivo móvil a través del navegador de Internet (navegador). Esta información se utiliza para registrar el número de visitas realizadas y recopilar información estadística sobre la actividad del sitio web.`,
          p5: `Utilizamos el término cookies en esta Política para referirnos a todos los archivos que recopilan información de esta manera.`,
          p6: `Tipos de Cookies según la entidad que las gestiona:`,

          p7: `Cookies propias: una cookie gestionada por este dominio/sitio web.`,
          p8: `Cookies de terceros: una cookie administrada por otro dominio/sitio web.`,
          p9: `Cookies persistentes: la información de las cookies se almacena permanentemente en su dispositivo.`,
          p10: `Cookies de sesión: la información de las cookies no se almacena permanentemente en su dispositivo.`,
          p11: `Exportech Portugal utiliza cookies de sesión (“cookies de sesión”) y cookies de origen (“cookies de origen”) así como cookies persistentes (“cookies persistentes”) y cookies de terceros (“cookies de terceros” ).`,
          p12: `Tipos de cookies según la finalidad de uso:`,

          p13: `Cookies técnicas: son aquellas que permiten navegar a través de un dominio/sitio web, utilizando diferentes opciones o servicios como, por ejemplo, identificar la sesión, acceder a áreas de acceso restringido y
          realizar el proceso de una orden de compra.`,
          p14: `Cookies de personalización: son aquellas que permiten el acceso al dominio/sitio web con algunas características predefinidas en función de un conjunto de decisiones tomadas, como por ejemplo el idioma utilizado.`,
          p15: `Cookies de análisis: son aquellas que permiten el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. 
          La información recogida a través de dichas cookies se utiliza, por ejemplo, para medir la actividad del sitio web y crear perfiles de navegación.`,
          p16: `Cookies publicitarias: son aquellas que permiten la gestión más eficaz de los espacios publicitarios en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.`,
          p17: `Cookies de publicidad comportamental: son aquellas que permiten la gestión de la forma más eficaz posible los espacios publicitarios. 
          Estas cookies almacenan información sobre el comportamiento del usuario, obtenida a través de la observación continuada de sus hábitos de navegación, permitiéndonos desarrollar un perfil específico para mostrar publicidad en función del mismo.`,
        },
      },
      block2: {
        title_header: `Términos y condiciones`,
        text1: {
          title: `1. ÁMBITO DE APLICACIÓN`,
          p1: `1.1. Las presentes Condiciones Generales establecen las condiciones aplicables a las ventas realizadas por Exportech, en adelante la Compañía, y el Cliente, en adelante el Cliente, y regulan los derechos y obligaciones de las partes, prevaleciendo sobre cualesquiera disposiciones legales no imperativas. `,
          p2: `1.2. Cualquier excepción o modificación a estas Condiciones Generales toma la forma de Condiciones Particulares y sólo será válida si está formulada por escrito, aceptada y firmada por los representantes legales de las partes, prevaleciendo estas últimas sobre las primeras.`,
          p3: `1.3. Serán nulas de pleno derecho cuantas condiciones o especificaciones que el Cliente pueda insertar en documentación de cualquier naturaleza que sean contradictorias con lo dispuesto en las Condiciones Generales o Particulares. `,
        },
        text2: {
          title: `2. SELECCIÓN DE PRODUCTO`,
          p1: `2.1. El Cliente es el único responsable de la selección del producto objeto de compra y venta, así como del uso o función al que está destinado. Por lo tanto (y de acuerdo con lo estipulado en los catálogos, tablas de precios y/o información general del producto de la Empresa), la Empresa no se responsabiliza ni garantiza que el producto sea apto para las aplicaciones técnicas previstas por el Cliente. , ni para la consecución, total o parcial, de los objetivos perseguidos por él al realizar su compra. Las imágenes contenidas en este catálogo son meramente ilustrativas y pueden diferir del aspecto original del
          equipamiento, por lo tanto, colores, formas y acabados están sujetos a posibles cambios, sin previo aviso.`,
        },
        text3: {
          title: `3. PEDIDOS`,
          p1: `3.1. En la fecha de pedido de los bienes y/o servicios que el Cliente pretende comprar, el Cliente está obligado a realizar la solicitud por escrito utilizando el formulario de la Compañía apropiado para ese fin, o por correo electrónico a comecial@store.exportech.com. .`,
          p2: `3.2. El plazo de entrega de la mercancía encargada, indicado por la Empresa, es meramente indicativo y en ningún caso vinculante, por lo que la Empresa no asume ninguna responsabilidad por la entrega de la mercancía fuera del plazo inicialmente indicado.`, 
        },
        text4: {
          title: `4. INSPECCIÓN DE MERCANCÍAS`,
          p1: `4.1. Todos los Bienes se venden con la calidad que poseen y en las condiciones en que se encuentran en la fecha de venta. Los Clientes podrán inspeccionar los bienes objeto de venta, previo acuerdo de la Empresa, considerando que el Cliente ha examinado los bienes objeto de venta o renunciado a este derecho desde el momento en que se presenta a la Empresa la respectiva propuesta de compra. `, 
        },
        text5: {
          title: `5. CANTIDADES`,
          p1: `5.1 Cualquier diferencia en las cantidades en las ventas de bienes realizadas por unidad debe ser comunicada inmediatamente a la Compañía por el Cliente, en el momento de la entrega o recogida de los bienes. En cualquier caso, la Empresa se reserva el derecho de inspeccionar dichos bienes antes de aceptar la reclamación realizada por el Cliente. `,
        },
        text6: {
          title: `6. CONDICIONES DE PAGO`,
          p1: `6.1. Salvo pacto en contrario por escrito, y sin perjuicio de lo dispuesto en la cláusula 3a, el precio de los bienes adquiridos por el Cliente será pagado a la Empresa en efectivo, previa emisión de la respectiva factura o documento equivalente. La falta de pago da lugar a la resolución inmediata del contrato y al incumplimiento del Cliente. `,
          p2: `6.2. La Compañía puede cambiar el precio de los bienes sin previo aviso. Sin embargo, siempre se le pagará el valor de la mercancía en la fecha de su pedido o, si la venta no fue precedida por un pedido, en la fecha de su pago.`,
          p3: `6.3. Los precios establecidos por la Sociedad están sujetos al IVA calculado al tipo legal vigente en la fecha de emisión de la factura de pago de los bienes adquiridos por el Cliente.`,
          p4: `6.4. Los bienes, incluso si están en posesión del Cliente, continúan siendo propiedad de la Compañía hasta que se cumplan plenamente todas las obligaciones del Cliente y la respectiva liquidación de los bienes en su totalidad.`,
          p5: `6.5.Todas las ventas no liquidadas en la fecha de vencimiento están sujetas a intereses de demora al tipo de interés medio aplicado por los bancos portugueses a los descubiertos en cuentas corrientes calculado en la fecha de vencimiento.`,
        },
        text7: {
          title: `7. SUMINISTRO, ENTREGA Y RECOGIDA DE MERCANCÍAS`,
          p1: `7.1. Los bienes se considerarán puestos a disposición del Cliente en el lugar definido por la Compañía, salvo acuerdo en contrario por escrito.`,
          p2: `7.2. Si la mercancía se entrega en un lugar definido por el Cliente, los gastos de envío correrán a cargo del Cliente y el riesgo correrá a cargo del Cliente.`,
          p3: `7.3. El Cliente deberá recoger los bienes adquiridos dentro de los 3 (tres) días hábiles posteriores al pago.`,
          p4: `7.4. Si el Cliente no realiza ningún pago a su vencimiento o no cumple oportunamente otras obligaciones que se le asignan, la Compañía podrá suspender el cumplimiento de sus funciones, en particular, limitando o cesando el soporte técnico.`,
        },
        text8: {
          title: `8. SOPORTE TÉCNICO`,
          p1: `8.1. El Cliente podrá en cualquier momento solicitar servicios al Departamento Técnico de la Empresa, los cuales serán prestados y cobrados en las condiciones y términos que defina la Empresa. `,
          p2: `8.2. Cualquier coste inherente al Servicio de Soporte Técnico será previamente comunicado al Cliente y aceptado por el Cliente por escrito.`,
        },
        text9: {
          title: `9. QUEJAS`,
          p1: `9.1. El plazo de reclamación es de 8 (ocho) días y siempre deberá ser justificado por el Cliente y acompañado de los bienes que motivaron el reclamo en las mismas condiciones en que fueron vendidos. `,
          p2: `9.2. El cliente deberá solicitar a la Empresa que le asigne un número de reparación/devolución (RMA) que identificará el producto, y sin el cual no será posible su análisis por parte del Departamento Técnico de la Empresa.`,
          p3: `9.3. Sólo se aceptarán reclamaciones previa opinión favorable del Departamento Técnico de la Empresa, el cual determinará si el equipo en cuestión deberá ser reparado o reemplazado, según lo decida la Empresa.`,
          p4: `9.4. El dictamen del Departamento Técnico se realizará en un plazo máximo de 15 (quince) días hábiles, luego de la recepción del material en la sede de la Empresa.`,
          p5: `9.5. Sólo se aceptarán cambios o devoluciones si se demuestra que el equipo está defectuoso.`,
          p6: `9.6. Todos los equipos enviados para reparación o cotizaciones de reparación solo se aceptarán cuando vayan acompañados de un formulario RMA debidamente completado (disponible en el sitio web de la Compañía).`,
        },
        text10: {
          title: `10. REPARACIONES`,
          p1: `10.1. A petición y requerimiento del Cliente, y previo contacto con el Departamento Técnico de la Compañía, los equipos adquiridos y pagados íntegramente por el Cliente podrán ser reparados en la sede de la Compañía.`,
          p2: `10.2. El Cliente deberá solicitar a la Empresa que le asigne un número de reparación/devolución (RMA) que identificará el producto, y sin el cual no será posible su análisis por parte del Departamento Técnico de la Empresa.  `,
          p3: `10.3. El equipo:`,
          p4: `10.3.1. Tras un análisis exhaustivo, no se detectó ninguna anomalía.`,
          p5: `10.3.2. No hay descripción detallada del fallo.`,
          p6: `10.3.3. En los casos en que no se aceptó el presupuesto de reparación propuesto, estarán sujetos al pago de una tarifa de análisis del equipo correspondiente al 15% del valor nuevo del mismo equipo. `,
          p7: `10.3.4. La Compañía excluye toda responsabilidad derivada del transporte del equipo a reparar.`,
        },
        text11: {
          title: `11. ELEMENTOS DE FUERZA MAYOR RELACIONADOS CON LA ENTREGA`,
          p1: `11.1. Después de la celebración del Contrato, la Compañía no será responsable de ningún retraso en la puesta a disposición del Cliente de los bienes objeto del Contrato, si dicho retraso se debe a fuerza mayor, caso fortuito o, en general, a situaciones que no pudieron razonablemente previsible o evitable.»`, 
        },
        text12: {
          title: `12. LÍMITES DE RESPONSABILIDAD DE LA EMPRESA`,
          p1: `12.1. En el caso de que, por cualquier motivo, exista retraso o falta en la entrega de los bienes, defecto de cantidad, género o cualquier inexactitud en la descripción de los bienes objeto 
          del Contrato, incluso si se debe a culpa o negligencia de la Compañía, la responsabilidad de esta última no cubrirá, en ningún caso, los daños indirectos sufridos por el Cliente, tales como la pérdida de una operación de reventa, negocio u otros beneficios similares o lucro cesante.`, 
        },
        text13: {
          title: `13. RESOLUCIÓN`,
          p1: `13.1. La Compañía tiene derecho a rescindir, total o parcialmente, el Contrato con efecto inmediato en los siguientes casos: `,
          p2: `13.1.1. Incumplimiento de las Condiciones Generales y/o Particulares;`,
          p3: `13.1.2. La sujeción a cualquier proceso de insolvencia, liquidación judicial o extrajudicial del Cliente, 
          la reducción de las garantías prestadas o, en general, cualquier modificación de su estructura jurídica que afecte a su solvencia;`,
          p4: `13.1.3. Terminación, por cualquier motivo, aunque sólo sea el cese de facto, de la actividad del Cliente;`,
          p5: `13.1.4. Concurrencia de cualesquiera otras causas de resolución previstas en el Contrato o en la Ley.`, 
        },
        text14: {
          title: `14. REDUCCIÓN DEL CONTRATO`,
          p1: `14.1. La nulidad, total o parcial, de cualquier disposición del Contrato, no afectará la validez de las restantes disposiciones, salvo que el interesado demuestre que la finalidad perseguida por la Empresa y el Cliente nos permite suponer que no habrían celebrado el contrato. transacción sin la parte inválida.`,
        },
        text15: {
          title: `15. CLÁUSULA PENAL`,
          p1: `En caso de retraso en el pago de una factura y su continuación después de que la Compañía haya solicitado 
          al Cliente el pago, el Cliente deberá pagar a la Compañía, además del monto correspondiente a la deuda principal y 
          los intereses respectivos, una cantidad correspondiente al 15% del importe adeudado, en concepto de cláusula penal.`
        },
        text16: {
          title: `16. JURISDICCIÓN COMPETENTE`,
          p1: `Para resolver cualquier cuestión que surja de este contrato, las partes acuerdan acudir al Tribunal de Distrito de Lisboa.`, 
        },
      },
      block3: {
        title_header: `Política de cambios y devoluciones`,
        subtitle:"CONDICIONES DE ACEPTACIÓN",
        text1: {
          numb: "1.",
          txt:`Las RMA siempre deben ir acompañadas de este formulario;`
        },   
        text2: {
          numb: "2.",
          txt:`Todos los Accesorios que acompañan al RMA deben estar indicados expresamente, de lo contrario no se aceptarán solicitudes de accesorios no indicados posteriormente. Si se indican accesorios que no están incluidos con el equipo, se devolverá el RMA y los costos de transporte correrán a cargo del Cliente;`
        },   
        text3: {
          numb: "3.",
          txt:`Los RMA que no revelen ningún daño se enviarán con los costos de transporte a cargo del Cliente y se cobrará una tarifa de 15,00 € por la Asistencia brindada;`
        },   
        text4: {
          numb: "4.",
          txt:`Cuando en el Período de Garantía, el Cliente corre con los costos de transporte a Exportech y Exportech corre con los costos de envío al cliente;`
        },
        text5: {
          numb: "5.",
          txt:`Las solicitudes de crédito deben realizarse en un plazo máximo de 14 Días Hábiles después de la emisión de la Factura, y a partir de esa fecha están sujetas a Evaluación y Aceptación por parte de Exportech Portugal;`
        },   
        text6: {
          numb: "6.",
          txt:`Sólo se aceptarán para Crédito Productos cuya verificación por parte de nuestros Servicios Técnicos verifique que los 4 Supuestos anteriores estén validados (Operación Normal | Paquete Completo | Caja en Buen Estado | Equipo Vendible);`
        },   
        text7: {
          numb: "7.",
          txt:`El equipo que no sea recogido o cuyo Pago de Reparación no sea pagado dentro de los 60 Días revertirá a Exportech Portugal;`
        },   
        text8: {
          numb: "8.",
          txt:`Todo equipo fuera del periodo de garantía está sujeto a un cargo de 15,00 € por el análisis respectivo. Este valor se diluirá en el costo de la Reparación si se acepta el Presupuesto;`
        },   
        text9: {
          numb: "9.",
          txt:`Todas las reparaciones se benefician de una garantía de 3 meses;`
        },
        text10: {
          numb: "10.",
          txt:`Todos los equipos para notas de crédito deben mencionar la contraseña del equipo en el campo de
           arriba. Si olvida o pierde su(s) contraseña(s), Exportech le cobrará 25€+IVA por el servicio único.`
        },   
        text11: {
          numb: "11.",
          txt:`Emisión de Notas de Crédito al momento de devolución del equipo por parte de los clientes: Hasta 15 días* – Sin devaluación 16 – 31 días* – 15% devaluación 32 – 60 días* – 40% devaluación *(suponiendo que el equipo sea perfectamente vendible, embalaje original, libre de riesgos y/o uso, accesorios, etc.); Después de 61 días – Exportech no acepta devoluciones.`
        },   
        text12: {
          numb: "12.",
          text:`12. Productos de Proyecto o Pedido destinado a un proyecto (Productos sin Stock) – Exportech no acepta devoluciones;`
        },
      }, 
    },

    about_locations:{
      l1:`Sede de Exportech Lisboa`,
      l2: `Sucursal Exportech Funchal`,
      l3:`Almacén Logístico`,
   },
 
  }
}

export default {
  'PT': PORTUGUESE,
  'EN': ENGLISH,
  'ES': SPANISH,
};
