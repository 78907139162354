import React from 'react'
import PageHeader from './Components/PageHeader';
import Navbar from './Components/Navbar';
import PartnersSection from './Components/PartnersSection';
import Footer from './Components/Footer';
import { BsArrowRight } from 'react-icons/bs';
import { FaInfo } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { BiCctv } from "react-icons/bi";
import { RiFireLine } from "react-icons/ri";
import { LuDoorOpen } from "react-icons/lu";
import { MdOutlineSimCard } from "react-icons/md";
import { TbCloudNetwork } from "react-icons/tb";
import { MdCable } from "react-icons/md";
import { FaNetworkWired } from "react-icons/fa6";
import { GiRobberHand } from "react-icons/gi";
import { GiRobber } from "react-icons/gi";
import { GiTurnstile } from "react-icons/gi";
import { GiMetalDisc } from "react-icons/gi";
import { FiAlertTriangle } from "react-icons/fi";
import { GiGasStove } from "react-icons/gi";
import { FaRoadBarrier } from "react-icons/fa6";
import Translator from '../components/I18n/Translator';


function Products() {
  document.title = "Produtos";
  const Data = [
    {
      title: <Translator path={"products.products_text_area2.text0"} />,
      link: "https://store.exportech.com.pt/product-category/cctv",
      icon:<><BiCctv /></>
    },
    {
      title:<Translator path={"products.products_text_area2.text1"} />,
      link: "https://store.exportech.com.pt/product-category/cabos",
      icon:<><MdCable /></>
    },
    {
      title: <Translator path={"products.products_text_area2.text2"} />,
      link: "https://store.exportech.com.pt/product-category/networking",
      icon:<><TbCloudNetwork /></>
    },
    {
      title: <Translator path={"products.products_text_area2.text3"} />,
      link: "https://store.exportech.com.pt/product-category/fibra-optica",
      icon:<><FaNetworkWired /></>
    },
    {
      title: <Translator path={"products.products_text_area2.text4"} />,
      link: "https://store.exportech.com.pt/product-category/sistemas-incendio",
      icon:<><RiFireLine /></>
    },
    {
      title:<Translator path={"products.products_text_area2.text5"} />,
      link: "https://store.exportech.com.pt/#:~:text=Sistemas%20Inc%C3%AAndio-,V%C3%ADdeo%20Porteiros,-IP",
      icon:<><LuDoorOpen /></>
    },
    {
      title:<Translator path={"products.products_text_area2.text6"} />,
      link: "https://store.exportech.com.pt/product-category/acessorios",
      icon:<><MdOutlineSimCard /></>
    },
    {
      title:<Translator path={"products.products_text_area2.text7"} />,
      link: "https://store.exportech.com.pt/product-category/sistemas-intrusao",
      icon:<><GiRobberHand /></>
    }, 
    {
      title: <Translator path={"products.products_text_area2.text8"} />,
      link: "https://store.exportech.com.pt/product-category/eas-anti-furto",
      icon:<><GiRobber /></>
    },
    {
      title:<Translator path={"products.products_text_area2.text9"} />,
      link: "https://store.exportech.com.pt/product-category/torniquetes",
      icon:<><GiTurnstile /></>
    },
    {
      title: <Translator path={"products.products_text_area2.text10"} />,
      link: "https://store.exportech.com.pt/product-category/deteccao-de-metais",
      icon:<><GiMetalDisc /></>
    }, 
    {
      title: <Translator path={"products.products_text_area2.text11"} />,
      link: "https://store.exportech.com.pt/product-category/sistemas-deteccao-gases",
      icon:<><GiGasStove /></>
    }, 
    {
      title:<Translator path={"products.products_text_area2.text12"} />,
      link: "https://store.exportech.com.pt/product-category/sistemas-de-emergencia",
      icon:<><FiAlertTriangle /></>
    },
    {
      title:<Translator path={"products.products_text_area2.text13"} />,
      link: "https://store.exportech.com.pt/product-category/solucoes-de-parqueamento",
      icon:<><FaRoadBarrier /></>
    }, 
  ];
 
  return (
    <div className='products-page'>
      <Navbar active={3} />
      <PageHeader Title={<Translator path={"products.title_header"} />} title2={"Produtos"} />
      <div className="wrapper">
        <div className="content">
          <div className="cat-options">
            <div className="image-box">
              <img src={require("../images/2smart.png")}  alt="2smart" />
            </div>
            <div className="text-area">
              <div className="title-info"> 
                <strong>2smart Software</strong>
                <h1 className='title'><Translator path={"products.products_text_area1.title"} /></h1>
                <div className="bar"></div>
              </div>
              <p><Translator path={"products.products_text_area1.text1"} /></p>
              <p><Translator path={"products.products_text_area1.text2"} /></p>
              <p><Translator path={"products.products_text_area1.text3"} /></p>
              <p><Translator path={"products.products_text_area1.text4"} /></p>
            <div className="col-lg-3">
            <a href="https://store.exportech.com.pt/product-brand/2smart" target="_blank" rel="noopener noreferrer">
                <button className="btn"> <Translator path={"products.products_text_area1.button"} />  <BsArrowRight /> </button>
              </a>
            </div>

            </div>
          </div>
        </div>
      </div>
      <div id="counter-1" class="page-section  achievements-section">
        <div class="wrapper">
          <div className="ach-content"> 
            <div className="title-info center">
              <strong> <Translator path={"products.products_text_area2.small"} /></strong>
              <h1 className='title'><Translator path={"products.products_text_area2.title"} /> </h1>
              <div className="bar"></div>
             </div>
            <br /><br />
            <div class="row text-center justify-center">
              {Data.map((item, index) => (
                <div class="col-xs-6 col-sm-3">
                  <a href={item.link} target='_blank'><div className="count"><FaInfo /></div></a>
                  <div className="inverted-radius">
                    <div className="info">
                    <div className="icon">{item.icon}</div>
                      <h3>{item.title}</h3> 
                    </div>
                  </div>
                </div>
              ))}
            </div> 
            <div className="title-info center">
              <a href="https://store.exportech.com.pt" target="_blank" rel="noopener noreferrer">
                <button className="btn"><Translator path={"products.products_text_area2.link"} /><BsArrowRight /> </button>
              </a>
            </div>
          </div>
        </div>
      </div> 
      <PartnersSection />
      <Footer />
    </div>
  )
}

export default Products
