import React from 'react'
import ImageLoading from '../../../components/ImageLoading'
import Translator from '../../../components/I18n/Translator'

function AboutFacts() {
    return (
        <div className='about-facts'>
            <div className="wrapper">
                <div className="content">
                    <div className="image-area">
                         <ImageLoading source={require("../../../images/facts.png")} alt="" />
                    </div>
                    <div className="text-area">
                        <div className="title-info">
                            <strong><Translator path={"about.about_text_area4.small"} /></strong>
                            <h1 className='title'><Translator path={"about.about_text_area4.title"} /></h1>
                            <div className="bar"></div>
                        </div>
                        <p>
                            <Translator path={"about.about_text_area4.description"} /> <br />
                            <Translator path={"about.about_text_area4.description2"} />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFacts
