import React from 'react'
import Slider from "react-slick";
import { GrMap } from "react-icons/gr";
import ImageLoading from '../../../components/ImageLoading';
import Translator from '../../../components/I18n/Translator';


function ContactOffices() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Office_1 = [
    require("../../../images/officeimages/1.1.jpg"),
    require("../../../images/officeimages/1.2.webp"),
    require("../../../images/officeimages/1.3.webp"),
    require("../../../images/officeimages/1.4.webp"),
    require("../../../images/officeimages/1.5.webp"),
    require("../../../images/officeimages/1.6.webp"),
    require("../../../images/officeimages/1.7.webp")
  ];

  const Office_2 = [
    require("../../../images/officeimages/3.1.webp"),
    require("../../../images/officeimages/3.2.webp"),
    require("../../../images/officeimages/3.3.webp"),
    require("../../../images/officeimages/3.4.webp"),
    require("../../../images/officeimages/3.5.webp"),
    require("../../../images/officeimages/3.6.webp")
  ];

  const Office_3 = [
    require("../../../images/officeimages/2.1.jpg"),
    require("../../../images/officeimages/2.2.jpg"),
    require("../../../images/officeimages/2.3.jpg"),
    require("../../../images/officeimages/2.4.webp"),
    require("../../../images/officeimages/2.5.webp"),
    require("../../../images/officeimages/2.6.webp"),
    require("../../../images/officeimages/2.7.webp"),
    require("../../../images/officeimages/2.8.webp"),
    require("../../../images/officeimages/2.9.webp")
  ];

  return (
    <div className='contact-info-offices'>
      <div className="wrapper">
        <div className="title-info center">
          <strong><Translator path={"contacts.contacts_text_area3.small"} /></strong>
          <h1 className='title' ><Translator path={"contacts.contacts_text_area3.title"} /></h1>
          <div className="bar"></div>
        </div>
        <section className="content">
          <article>
            <div className="image-wrapper">
              <Slider {...settings}>
                {Office_1.map((item, index) => (
                  <ImageLoading source={item} />
                ))}
              </Slider>
            </div>
            <h3><Translator path={"about_locations.l1"} /></h3>
            <p>
              Rua Fernando Farinha nº 2A e 2B
              Braço de Prata - Lisboa 1950-448
            </p>
            <p>
              +351 210 353 555 | +351 212 456 082 <br />
              geral@exportech.com.pt
            </p>
            <a href="https://www.google.pt/maps/search/Rua+Fernando+Farinha+n%C2%BA+2A+e+2B+Bra%C3%A7o+de+Prata+-+Lisboa+1950-448.+exportech/@38.7470677,-9.1062008,17z/data=!3m1!4b1?hl=pt-PT&entry=ttu" className='view' target="_blank" rel="noopener noreferrer">
              <GrMap /><span><Translator path={"contacts.contacts_text_area3.link"} /></span>
            </a>
          </article>
          <article>
            <div className="image-wrapper">
              <Slider {...settings}>
                {Office_2.map((item, index) => (
                  <ImageLoading source={item} alt="" />
                ))}
              </Slider>
            </div>
            <h3><Translator path={"about_locations.l2"} /></h3>
            <p>
              Rua da Capela do Amparo Edifício Alpha Living Loja A 9000-267 Funchal
            </p>
            <p>
              +351 291 601 603 | +351 968 084 534<br />
              geral@exportech.com.pt
              <br />
            </p>
            <a href="https://www.google.pt/maps/place/EXPORTECH+-+FUNCHAL/@32.6449469,-16.9431819,17z/data=!4m15!1m8!3m7!1s0xc605ff24d96cccf:0x93a9908fef4d2f47!2sEXPORTECH+-+FUNCHAL!8m2!3d32.6449274!4d-16.942999!10e5!16s%2Fg%2F11j_653yw5!3m5!1s0xc605ff24d96cccf:0x93a9908fef4d2f47!8m2!3d32.6449274!4d-16.942999!16s%2Fg%2F11j_653yw5?hl=pt-PT&entry=ttu" className='view' target="_blank" rel="noopener noreferrer">
              <GrMap /><span><Translator path={"contacts.contacts_text_area3.link"} /></span>
            </a>
          </article>
          <article>
            <div className="image-wrapper">
              <Slider {...settings}>
                {Office_3.map((item, index) => (
                  <ImageLoading source={item} alt="" />
                ))}
              </Slider>
            </div>
            <h3><Translator path={"about_locations.l3"} /></h3>
            <p>
              Estrada do Contador nº 25 - Fracção B Sesmaria do Colaço 2130-223 Benavente</p>
            <p>
              +351 210 353 555 | +351 967 018 509<br />
              geral@exportech.com.pt
            </p>
            <a href="https://www.google.pt/maps/place/Exportech+Portugal+-+Armaz%C3%A9m+Log%C3%ADstico/@38.954304,-8.820802,17z/data=!3m1!4b1!4m6!3m5!1s0xd191f9493f5f125:0x31dd3e9a5fb6fe8f!8m2!3d38.954304!4d-8.8182271!16s%2Fg%2F11y3nr2zh4?hl=pt-PT&entry=ttu" className='view' target="_blank" rel="noopener noreferrer">
              <GrMap /><span><Translator path={"contacts.contacts_text_area3.link"} /></span>
            </a>
          </article>
        </section>
      </div>
    </div>
  )
}

export default ContactOffices
