import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { TiThMenu } from "react-icons/ti";
import { CgClose } from "react-icons/cg"; 
import { useTranslation } from 'react-i18next';
import I18n from '../../components/I18n/I18n';
import Translator from '../../components/I18n/Translator';

function Navbar({ active }) {
    const [scrolled, setScrolled] = useState(false);
    const [showCloseIcon, setshowCloseIcon] = useState(false); 
    const { i18n, t } = useTranslation();

    const onChangeLang = (e) => { 
        i18n.changeLanguage(e);
        const selectedLanguage = i18n.language // Idioma selecionado
        alert(selectedLanguage)

      };


    const getScrollPosition = (el = document.documentElement) => ({
        x: el.scrollLeft,
        y: el.scrollTop
    });

    function GetPosition() {
        window.addEventListener('scroll', () => {
            let val = getScrollPosition();
            if (val.y > 100) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        });
    }


    useEffect(() => {
        GetPosition();
    });



    return (
        <div>
            <header id="nav" class={scrolled ? "header header-1 black-header mobile-no-transparent fixed" : " header header-1 black-header mobile-no-transparent"}>
                <div class="header-wrapper">
                    <div class="container-m-30 clearfix">
                        <div class="logo-row">
                            <div class="logo-container-2"> 
                                <div class="logo-2">
                                    <Link to="/" class="clearfix">
                                        <img src={require("../../images/logo.png")} class="logo-img" alt="Logo" />
                                    </Link>
                                </div>
                            </div>
                            <div  >
                                <div class="menu-btn-respons-container" onClick={() => setshowCloseIcon(showCloseIcon === false ? true : false)}>
                                    <button type="button" class="navbar-toggle btn-navbar collapsed" data-toggle="collapse" data-target="#main-menu .navbar-collapse">
                                        {showCloseIcon ? <div className='menu-ico'><CgClose /></div> : <div className='menu-ico'><TiThMenu /></div>}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="main-menu-container">
                        <div class="container-m-30 clearfix">
                            <div id="main-menu">
                                <div class="navbar navbar-default" role="navigation">
                                    <nav class={showCloseIcon ? "collapse collapsing navbar-collapse right-1024 in" : "collapse collapsing navbar-collapse right-1024"}
                                        style={{ height: (showCloseIcon ? "auto" : "0px") }} aria-expanded={showCloseIcon ? false : true} >
                                        <ul class="nav navbar-nav">
                                            <li onClick={() => setshowCloseIcon(showCloseIcon === false ? true : false)} class={active === 1 ? "parent current" : "parent"}>
                                                <Link to="/"><div class="main-menu-title">{<Translator path="navbar.link1" />}</div></Link>
                                            </li> 
                                            <li onClick={() => setshowCloseIcon(showCloseIcon === false ? true : false)} class={active === 2 ? "parent current" : "parent"}>
                                                <Link to="/about"><div class="main-menu-title">Exportech</div></Link>
                                            </li> 
                                            <li onClick={() => setshowCloseIcon(showCloseIcon === false ? true : false)} class={active === 3 ? "parent current" : "parent"}>
                                                <Link to="/products"><div class="main-menu-title">{<Translator path="navbar.link2" />}</div></Link>
                                            </li>  
                                            <li onClick={() => setshowCloseIcon(showCloseIcon === false ? true : false)} class={"parent"}>
                                                <a href="https://store.exportech.com.pt" target='_blank'><div class="main-menu-title">{<Translator path="navbar.link5" />}</div></a>
                                            </li>
                                            <li onClick={() => setshowCloseIcon(showCloseIcon === false ? true : false)} id="menu-contact-info-big" class={active === 5 ? "parent current" : "parent"}>
                                                <Link to="/contacts"><div class="main-menu-title">{<Translator path="navbar.link4" />}</div></Link>
                                            </li> 
                                            <I18n  /> 
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Navbar
