import React from 'react'
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import PageHeader from './Components/PageHeader';
import AboutText from './Components/AboutComponents/AboutText';
import AboutBusiness from './Components/AboutComponents/AboutBusiness';
import AboutOffices from './Components/AboutComponents/AboutOffices';
import AboutFacts from './Components/AboutComponents/AboutFacts';
import AboutEmployees from './Components/AboutComponents/AboutEmployees';
import Translator from '../components/I18n/Translator';

function About() {
    document.title = "Sobre a Exportech Portugal";

  return (
    <div >
       <Navbar active={2} />
       <PageHeader  title2={"Sobre nós"} Title={<Translator path={"about.title_header"} />} />
       <AboutText />
        <AboutBusiness />
        <AboutOffices />
        <AboutFacts />
        <AboutEmployees />
       <Footer />
    </div>
  )
}

export default About
